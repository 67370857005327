import { Component,  NgZone } from "@angular/core";
import { IonicModule, Platform } from "@ionic/angular";
import { CommonModule } from "@angular/common";
import { Storage } from "@ionic/storage-angular";

import { register } from "swiper/element/bundle";
import { NetworkService } from "@/app/services/network.service";
import { Select, Store } from "@ngxs/store";
import { AuthState } from "@/app/state/auth/auth.state";
import { Observable } from "rxjs";
import { SyncService } from "@/app/services/sync.service";
import { ShowLoader } from "@/app/state/loader/loader.actions";
import { SecureStorageService } from "@/app/services/secure-storage.service";
import { CancelOrder, QueryPrepaidOrderStatus } from "@/app/state/prepaid-bundles/prepaid-bundles.actions";
import { Router } from "@angular/router";
import { Logout } from "@/app/state/auth/auth.actions";

register();

@Component({
    selector: "app-root",
    templateUrl: "app.component.html",
    styleUrls: ["app.component.scss"],
    standalone: true,
    imports: [IonicModule, CommonModule],
    providers: [NetworkService, SecureStorageService, SyncService],
})
export class MVNOAppComponent {
    isLoggedIn = false;
    @Select(AuthState.isAuthenticated) authenticated$!: Observable<boolean[]>;

    constructor(
        private network: NetworkService,
        private platform: Platform,
        private storage: Storage,
        private secureStorage: SecureStorageService,
        private store: Store,
        private syncService: SyncService,
        private zone: NgZone,
        private router: Router
    ) {
        this.initializeApp();
    }

    async initializeApp() {
        this.platform.ready().then(async () => {
            await this.storage.create();
            await this.network.checkNetworkStatus();

            this.authenticated$.subscribe(async (status) => {
                if (!status) {
                    await this.syncService.startAuth();
                    await this.syncService.startBusinessPartner();

                    this.syncService.foregroundSync();
                }
            });
        });
    }

    async handleRedirects(route: string | undefined) {
        switch (route) {
            case "return":
                console.log("on payu return");
                this.handleReturnRoute();
                break;
            case "cancel":
                console.log("on payu cancel");
                setTimeout(() => this.store.dispatch(new CancelOrder()), 2000);
                break;
            case "rica-cancel":
            case "rica-complete":
                console.log("on rica cancel and complete");
                await this.router.navigateByUrl("/tabs/home");
                break;
            default:
                console.log("on default");
                this.store.dispatch(new Logout()); // Logout and redirect to log in page
                break;
        }
    }

    handleReturnRoute() {
        this.store.dispatch(new ShowLoader({ message: "please wait..", showLoader: true }));
        console.log("Inside handler");
        this.secureStorage
            .get("activeOrder")
            .then((activeOrder: any) => {
                console.log(`Active order: ${activeOrder}`);
                if (activeOrder !== null && activeOrder !== undefined) {
                    this.store.dispatch(
                        new QueryPrepaidOrderStatus({
                            orderRef: activeOrder.orderRef,
                            bp: activeOrder.bp,
                        })
                    );
                }
            })
            .catch((err: any) => console.log(`Storage error: ${err}`));
    }
}
