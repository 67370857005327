export const environment = {
    production: false,
    AUTH_REST_URI: "https://q-login.pnp.co.za",
    BASE_URI: "https://q-api.pnp.co.za",
    CLIENT_ID: "25e09fb0-3f51-4f2c-9133-07c9da7f5052",
    SECRET_ID: "9NCTmfARoYBPzi8ePBz6LnyG6F5BB8ccN3x4Een4",
    RESET_PASSWORD_URI: "https://storefront-hyb-qa.pnp.co.za/reset-password?redirectUrl=https://q-pnpmobile.pnp.co.za",
    S3_DEV_QA_CDN: "https://pnp-cdn-test.s3.eu-west-1.amazonaws.com/mvno",
    S3_PROD_CDN: "https://pnp-cdn-prod.s3.eu-west-1.amazonaws.com/mvno",
    RICA_URI: "https://registration-pnp-mobile-webapp.kyc.business/onboarding",
    PAYU_RETURN_URI: "https://q-pnpmobile.pnp.co.za/payu/return",
    PAYU_CANCEL_URI: "https://q-pnpmobile.pnp.co.za/payu/cancel"
};
