<div class="sim-link">
  <ion-row class="icon-row">
    <ion-col size="12">
      <div lines="none" class="header--icon">
        <img [src]="'assets/images/icons/sim.svg'" alt="" />
      </div>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="12">
      <div class="content--section">
        <h1>No SIM linked</h1>
        <p>
          Link your PnP Mobile SIM to access services including Airtime and Data
          top-up.
        </p>
      </div>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-button
      class="full"
      color="pnp-navy"
      [routerLink]="'/auth/registration/prompt-rica'"
    >
      <ion-icon src=" assets/images/icons/sim-ico.svg" class="retry-icon">
      </ion-icon>
      link my sim
    </ion-button>
  </ion-row>
</div>
