export class Crypto {
    static readonly m = Math.pow(2, 32);
    static readonly a = 1664525;
    static readonly c = 1013904223;

    static encrypt(text: string, seed?: number) {
        if (!text || (text && text.length === 0)) return "";
        let rnd = seed || Math.floor(Math.random() * Crypto.m);
        const result: string[] = [];
        for (let i = 0; i < 4; ++i) {
            result.push(String.fromCharCode((rnd >> (8 * i)) & 0xff));
        }
        let index = 0;
        let prevIndex: number;
        let run: number;
        let maxRun = 65535;
        let count = text.length;
        let mask: number;
        while (index < count) {
            prevIndex = index;
            run = 0;
            const isUnicode = text.charCodeAt(index) > 255;
            while (++run < maxRun && ++index < count) {
                if (isUnicode !== text.charCodeAt(index) > 255) {
                    break;
                }
            }
            run === maxRun && ++index;
            rnd = (Crypto.a * rnd + Crypto.c) % Crypto.m;
            mask = rnd & 0xff;
            result.push(String.fromCharCode((isUnicode ? 1 : 0) ^ mask));
            for (let i = 0; i < 2; ++i) {
                rnd = (Crypto.a * rnd + Crypto.c) % Crypto.m;
                mask = rnd & 0xff;
                result.push(String.fromCharCode(((run >> (8 * i)) & 0xff) ^ mask));
            }
            for (let i = 0; i < run; ++i) {
                rnd = (Crypto.a * rnd + Crypto.c) % Crypto.m;
                mask = isUnicode ? rnd & 0xffff : rnd & 0xff;
                result.push(String.fromCharCode(text.charCodeAt(prevIndex + i) ^ mask));
            }
        }
        return result.join("");
    }

    static decrypt(text: string) {
        try {
            if (text.length === 0) return "";
            let rnd = 0;
            for (let i = 0; i < 4; ++i) {
                rnd |= text.charCodeAt(i) << (8 * i);
            }
            const result: string[] = [];
            let index = 4;
            let run: number;
            let count = text.length;
            let mask: number;
            while (index < count - 1) {
                rnd = (Crypto.a * rnd + Crypto.c) % Crypto.m;
                mask = rnd & 0xff;
                let isUnicode = !!(text.charCodeAt(index++) ^ mask);
                run = 0;
                for (let i = 0; i < 2; ++i) {
                    rnd = (Crypto.a * rnd + Crypto.c) % Crypto.m;
                    mask = rnd & 0xff;
                    run |= (text.charCodeAt(index++) ^ mask) << (8 * i);
                }
                for (let i = 0; i < run; ++i) {
                    rnd = (Crypto.a * rnd + Crypto.c) % Crypto.m;
                    mask = isUnicode ? rnd & 0xffff : rnd & 0xff;
                    result.push(String.fromCharCode(text.charCodeAt(index++) ^ mask));
                }
                isUnicode = !isUnicode;
            }
            return result.join("");
        } catch (ex) {
            return "";
        }
    }
}
