import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'balanceFormatter'
})
export class BalanceFormatterPipe implements PipeTransform {

  // @ts-ignore
  transform(bytes: any, decimals: any = 2): unknown {
    try {
      if (bytes === 0) return "0 KB";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    } catch (error) {
      //Todo: do something with the error
    }
  }

}
