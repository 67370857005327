import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { Config } from "../config";
import { AuthBasicCredentials, AuthTokenCredentials } from "src/app/domain";

import { CheckRefreshToken, RefreshToken, SyncAuth } from "src/app/state/auth/auth.actions";
import {
    GetBP,
    GetMsisdns,
    GetSmartShopperBlance,
    SyncBusinessPartner,
} from "src/app/state/business-partner/business-partner.actions";
import { SecureStorageService } from "./secure-storage.service";

const { authBasicCredentials: authBasicCredentialsKey, authTokenCredentials: authTokenCredentialsKey } = Config.Auth;

@Injectable({
    providedIn: "root",
})
export class SyncService {
    constructor(private secureStorage: SecureStorageService, private store: Store) {}

    public async startAuth() {
        const keys: string[] = [authBasicCredentialsKey, authTokenCredentialsKey];
        const promises: Promise<any>[] = [];

        keys.forEach((key) => {
            promises.push(this.secureStorage.get(key));
        });

        return await Promise.all(promises)
            .then((result) => {
                if (result[0] !== null && result[1] !== null && result[0] !== undefined && result[1] !== undefined) {
                    this.store.dispatch(
                        new SyncAuth(AuthBasicCredentials.fromJson(result[0]), AuthTokenCredentials.fromJson(result[1]))
                    );
                } else this.store.dispatch(new CheckRefreshToken());
            })
            .catch((error) => {
                console.log("Error syncing", error);
                //Todo global network or state error handling
                throw error;
            });
    }

    public async startBusinessPartner() {
        const keys: string[] = [
            "businessPartnerKey",
            "smartShopperKey",
            "msisdnKey",
            "selectedMsisdnKey",
            "primaryMsisdnKey",
            "primaryMsisdnRewardInfoKey",
        ];
        const promises: Promise<any>[] = [];

        keys.forEach((key) => {
            promises.push(this.secureStorage.get(key));
        });
        return await Promise.all(promises)
            .then((result) => {
                this.store.dispatch(
                    new SyncBusinessPartner(result[0], result[1], result[2], result[3], result[4], result[5])
                );
            })
            .catch((onError) => {
                //Todo Log the global network or state error handling
                //console.log(onError);
            });
    }

    public async foregroundSync() {
        this.store.dispatch(new GetBP()).subscribe(() => {
            this.store.dispatch(new GetSmartShopperBlance());
            this.store.dispatch(new GetMsisdns());
        });
        //check refresh token ->
        /* this.store.dispatch(new CheckRefreshToken()).subscribe(() => {
            this.store.dispatch(new GetBP()).subscribe(() => {
                this.store.dispatch(new GetSmartShopperBlance());
                this.store.dispatch(new GetMsisdns());
            });
        });*/
    }
}
