export class ShowLoader {
  static readonly type = "[Loading] ShowLoader";
  constructor(public payload: { showLoader: boolean; message?: string }) {}
}

export class HideLoader {
  static readonly type = "[Loading] HideLoader";
  constructor(public payload: { hideLoader: boolean }) {}
}

export class ShowUserFeedback {
  static readonly type = "[Loading] ShowUserFeedback";
  constructor(
    public feedback: {
      isError?: Boolean;
      cancel?: Boolean;
      title?: String;
      message: String;
      redirect?: String;
      btnText: String;
      link?: String;
      icon?: String;
      btnIcon?: String;
    }
  ) {}
}
