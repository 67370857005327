import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {IonicModule} from '@ionic/angular';
import {BalanceFormatterPipe} from '@/app/pipes/balance-formatter.pipe';
import {SimNotLinkedComponent} from '@/app/shared/sim-not-linked/sim-not-linked.component';

const pages: any = [
  BalanceFormatterPipe,
  SimNotLinkedComponent,
];


@NgModule({
  declarations: [pages],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    IonicModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    pages
  ]
})
export class SharedModule { }
