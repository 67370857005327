import { BusinessPartner,  } from "src/app/models";
import { Msisdn } from "src/app/models/msisdn";
import { SmartShopperCard } from "src/app/models/smart-shopper-card";

export class GetBP {
  static readonly type = "[Main] GetBP";

  //Required for call before login complete
  constructor(public token?: String, public guid?: String) { }
}

export class GetSmartShopperBlance {
  static readonly type = "[Main] GetSmartShopperBlance";

  //Required for call before login complete
  constructor(public token?: string) { }
}

export class GetMsisdns {
  static readonly type = "[Main] GetMsisdns";
}

export class SetSelectedMsisdn {
  static readonly type = "[Main] SetSelectedMsisdn";

  constructor(public selectedMsisdn: Msisdn) { }
}

export class SyncBusinessPartner {
  static readonly type = "[Main] SyncBusinessPartner";

  constructor(
    public businessPartner: BusinessPartner,
    public smartShopperCard: SmartShopperCard,
    public msisdns: Array<Msisdn>,
    public selectedMsisdn: Msisdn,
    public primaryMsisdn: Msisdn,
    public primaryMsisdnRewardInfo: any
  ) { }
}

export class AccountLinkOptions {
  static readonly type = "[Main] AccountLinkOptions";

  constructor(public id_passport: String, public value: String) { }
}

export class AccountLinkBp {
  static readonly type = "[Main] AccountLinkBp";
  constructor(
    public payload: {
      identificationType: string;
      identificationNumber: string;
      mvnoAccountNumber: string;
      businessPartnerId: string;
    }
  ) { }
}

export class AccountLinkMsisdnRewards {
  static readonly type = "[Main] AccountLinkMsisdnRewards";
  constructor(
    public payload: {
      msisdn: string;
      businessPartnerId: string;
      mvnoAccountNumber: string;
    }
  ) { }
}

export class ResetPassword {
  static readonly type = "[Main] ResetPassword";

  constructor(public username: String) { }
}

export class GetMsisdnBalance {
  static readonly type = "[Main] GetMsisdnBalance";

  constructor(public selectedMsisdn: Msisdn) { }
}

export class TransferReward {
  static readonly type = "[Main] TransferReward";

  constructor(public value: number) { }
}

export class TransferData {
  static readonly type = "[Main] TransferData";

  constructor(public payload: any) { }
}

export class UpdateMsisdnAlias {
  static readonly type = "[Main] UpdateMsisdnAlias";

  constructor(public payload: any) { }
}

export class ClearApiFunctionCompleted {
  static readonly type = "[Main] ClearApiFunctionCompleted";
}

export class ResetBusinessPartner {
  static readonly type = "[Main] ResetBusinessPartner";
}

export class GetRewardDataTransferAmounts {
  static readonly type = "[Main] GetRewardDataTransferAmounts";
}

export class GetRewardInfoCMS {
  static readonly type = "[Main] GetRewardInfoCMS";

  constructor(public showLoader: boolean = true) { }
}


export class GetPromoInfoCMS {
  static readonly type = "[Main] GetPromoInfoCMS";

  constructor(public showLoader: boolean = true) { }
}

export class GetDataTransferAmounts {
  static readonly type = "[Main] GetDataTransferAmounts";
}

export class GetAirtimeConvertAmounts {
  static readonly type = "[Main] GetAirtimeConvertAmounts";
}

export class GetRewardInfo {
  static readonly type = "[Main] GetRewardInfo";
}

export class GetBusinessPartnerSavedPaymentCards {
  static readonly type = "[Main] GetBusinessPartnerSavedPaymentCards";

  constructor(
    public payload: {
      businessPartnerId: any;
    }
  ) { }
}

export class ClearSavedCreditCards {
  static readonly type = "[Main] ClearSavedCreditCards";
}
