import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { HideLoader, ShowLoader, ShowUserFeedback } from "../loader/loader.actions";
import { PrepaidBundleDto, PrepaidBundlesDto as PrepaidBundles } from "src/app/domain/syncable/prepaid-bundles";
import { PrepaidBundleModel, PrepaidOrder, SelectedPaymentMethodsModel } from "src/app/models";
import { PnPHttpService } from "src/app/services/pnp-http.service";

import {
    BuyAirtimeBundles,
    BuyDataBundles,
    LoadAirtimeAndDataBundles,
    ConvertAirtimeBundles,
    SetSelectedBundle,
    CreatePrepaidOrder,
    SetSelectedPaymentMethod,
    QueryPrepaidOrderStatus,
    ResetPrepaidState,
    DestroyPrepaidOrder,
    AbortOrder,
    CancelOrder,
} from "./prepaid-bundles.actions";
import { GetMsisdns, SetSelectedMsisdn } from "../business-partner/business-partner.actions";
import { ModalController } from "@ionic/angular";
import { SecureStorageService as Storage } from "src/app/services/secure-storage.service";

@State<PrepaidBundleModel>({
    name: "prepaidBundles",
    defaults: {
        dataBundles: [],
        airtimeBundles: [],
        airtimeVariable: undefined,
        dataVariable: undefined,
        selectedBundle: undefined,
        activeOrder: undefined,
        selectedPaymentMethods: {
            creditCard: undefined,
            smartShopperCard: undefined,
            redeemAmount: 0,
            amount: 0,
            payWithNewCard: false,
            saveNewCard: false,
        },
    },
})
@Injectable()
export class PrepaidBundlesStates {
    abortTimer = 0;
    constructor(
        private store: Store,
        private storage: Storage,
        private httpService: PnPHttpService,
        public modalCtrl: ModalController
    ) {}

    @Selector()
    static getAllAirtimeBundles(state: PrepaidBundleModel) {
        return state.airtimeBundles;
    }

    @Selector()
    static getDataVariable(state: PrepaidBundleModel) {
        return state.dataVariable;
    }

    @Selector()
    static getAirtimeVariable(state: PrepaidBundleModel) {
        return state.airtimeVariable;
    }

    @Selector()
    static getSelectedBundle(state: PrepaidBundleModel) {
        return state.selectedBundle;
    }

    @Selector()
    static getSelectedPaymentMethods(state: PrepaidBundleModel) {
        return state.selectedPaymentMethods;
    }

    @Selector()
    static getActiveOrder(state: PrepaidBundleModel) {
        return state.activeOrder;
    }

    createPrepaidBundle(bundle: PrepaidBundleDto): PrepaidBundleDto {
        return new PrepaidBundleDto(
            bundle.maxAmount,
            bundle.minAmount,
            bundle.notes,
            bundle.price,
            bundle.priceUnit,
            bundle.productDescription,
            bundle.productId,
            bundle.productName,
            bundle.productType,
            bundle.validDays
        );
    }

    @Action(LoadAirtimeAndDataBundles)
    loadBundles({ getState, setState }: StateContext<PrepaidBundleModel>, action: LoadAirtimeAndDataBundles) {
        return this.httpService.fetchesBundlesCatalogue(action.payload.businessPartnerId).pipe(
            tap((response: any) => {
                const state = getState();

                const airtimeBundles: PrepaidBundleDto[] = [];
                const dataBundles: PrepaidBundleDto[] = [];
                let airtimeVariable = null;
                let dataVariable = null;

                if (response.length > 0) {
                    response.forEach((bundle: PrepaidBundleDto) => {
                        switch (bundle.productType) {
                            case "AIRTIME_VARIABLE":
                                airtimeVariable = this.createPrepaidBundle(bundle);
                                break;
                            case "AIRTIME_FIXED":
                                airtimeBundles.push(this.createPrepaidBundle(bundle));
                                break;
                            case "DATA_VARIABLE":
                                dataVariable = this.createPrepaidBundle(bundle);
                                break;
                            case "DATA_FIXED":
                                dataBundles.push(this.createPrepaidBundle(bundle));
                                break;
                            default:
                                break;
                        }
                    });

                    setState({
                        ...state,
                        dataBundles: dataBundles,
                        airtimeBundles: airtimeBundles,
                        airtimeVariable: airtimeVariable,
                        dataVariable: dataVariable,
                    });
                }
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                //console.log(errorResponse);
                //Todo: handle error msg

                return of();
            })
        );
    }

    @Action(BuyAirtimeBundles)
    buyAirtime() {}

    @Action(BuyDataBundles)
    buyData() {}

    @Action(ConvertAirtimeBundles)
    convertAirtime({ getState, setState }: StateContext<PrepaidBundleModel>, action: ConvertAirtimeBundles) {
        this.store.dispatch(
            new ShowLoader({
                showLoader: true,
                message: "",
            })
        );
        return this.httpService.convertAirtime(action?.payload).pipe(
            tap((response: any) => {
                this.store.dispatch(new HideLoader({ hideLoader: false }));
                this.store.dispatch(
                    new ShowUserFeedback({
                        title: "Success!",
                        message: `You have successfully converted <br /><span>${action.payload.selectedBundle.product_price}</span> to <span>${action.payload.selectedBundle.product_name}</span>`,
                        icon: "okay-icon.svg",
                        redirect: "/home",
                        btnText: "no-btn",
                    })
                );
                //Added timeout due to delays on getBalance
                setTimeout(() => {
                    this.store.dispatch(new GetMsisdns());
                }, 4000);
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                this.store.dispatch(new HideLoader({ hideLoader: false }));

                this.store.dispatch(
                    new ShowUserFeedback({
                        title: "Oops!",
                        message: errorResponse.error.message,
                        btnText: "Back to convert",
                        icon: "error-low-balance.svg",
                        btnIcon: "assets/images/icons/arrow-left-white.svg",
                        link: "/tabs/top-up",
                    })
                );

                return of();
            })
        );
    }

    @Action(SetSelectedBundle)
    setSelectedBundle({ getState, setState }: StateContext<PrepaidBundleModel>, action: SetSelectedBundle) {
        const state = getState();

        setState({
            ...state,
            selectedBundle: action.payload?.bundle ?? undefined,
        });

        return of();
    }

    @Action(SetSelectedPaymentMethod)
    setSelectedPaymentMethodsAndAmount(
        { getState, setState }: StateContext<PrepaidBundleModel>,
        action: SetSelectedPaymentMethod
    ) {
        const state = getState();
        let options: SelectedPaymentMethodsModel;
        if (action.selectedMethods !== null && action.selectedMethods !== undefined) {
            options = {
                creditCard: action.selectedMethods.creditCard
                    ? action.selectedMethods.creditCard
                    : action.selectedMethods.creditCard == null
                    ? action.selectedMethods.creditCard
                    : state.selectedPaymentMethods?.creditCard,
                smartShopperCard: action.selectedMethods.smartShopperCard
                    ? action.selectedMethods.smartShopperCard
                    : action.selectedMethods.smartShopperCard == null
                    ? action.selectedMethods.smartShopperCard
                    : state.selectedPaymentMethods?.smartShopperCard,
                redeemAmount: action.selectedMethods.redeemAmount
                    ? action.selectedMethods.redeemAmount
                    : action.selectedMethods.redeemAmount == 0
                    ? action.selectedMethods.redeemAmount
                    : state.selectedPaymentMethods?.redeemAmount,
                amount: action.selectedMethods.amount
                    ? action.selectedMethods.amount
                    : action.selectedMethods.amount == 0
                    ? action.selectedMethods.amount
                    : state.selectedPaymentMethods?.amount,
                payWithNewCard: !!action.selectedMethods.payWithNewCard,
                saveNewCard:
                    action.selectedMethods.saveNewCard !== undefined ? action.selectedMethods.saveNewCard : false,
            };
        } else {
            options = {
                creditCard: undefined,
                smartShopperCard: undefined,
                redeemAmount: 0,
                amount: 0,
                payWithNewCard: false,
                saveNewCard: false,
            };
        }

        setState({
            ...state,
            selectedPaymentMethods: options,
        });

        return of();
    }

    @Action(CreatePrepaidOrder)
    createPrepaidOrder({ getState, setState }: StateContext<PrepaidBundleModel>, action: CreatePrepaidOrder) {
        this.store.dispatch(
            new ShowLoader({
                showLoader: true,
                message: "creating your order...",
            })
        );
        this.abortTimer = 0;
        const state = getState();
        this.storage.remove("activeOrder");
        return this.httpService.createBundlePaymentOrder(action.payload.order, action.payload.bp).pipe(
            tap((response: any) => {
                const orderResponse: PrepaidOrder = {
                    orderResponse: response.orderResponse,
                    redirectUrls: response.redirectUrls ? response.redirectUrls : null,
                };

                setState({
                    ...state,
                    activeOrder: orderResponse,
                });
                const activeOrder = {
                    orderRef: orderResponse.orderResponse.orderReference,
                    bp: action.payload.bp,
                    bundle: state.selectedBundle,
                };
                this.storage.set("activeOrder", activeOrder);
                this.store.dispatch(new HideLoader({ hideLoader: false }));
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                this.modalCtrl.dismiss();
                this.store.dispatch(new HideLoader({ hideLoader: false }));
                this.storage.remove("activeOrder");
                this.store.dispatch(
                    new ShowUserFeedback({
                        isError: true,
                        title: "Oops!",
                        message: errorResponse.error.message,
                        btnText: "TRY AGAIN",
                        icon: "payments-error-icon.svg",
                        btnIcon: "assets/images/icons/retry.svg",
                        link: "/tabs/top-up",
                        cancel: true,
                    })
                );

                return of();
            })
        );
    }

    @Action(QueryPrepaidOrderStatus)
    getActiveOrderStatus({ getState, setState }: StateContext<PrepaidBundleModel>, action: QueryPrepaidOrderStatus) {
        setTimeout(() => {
            this.store.dispatch(new HideLoader({ hideLoader: false }));
        }, 10);
        const message = [
            "Your transaction is being processed.",
            "This seems to be taking a bit longer than usual.",
            "Still working… please give us a few more moments.",
        ];
        let loadingMessage = message[0];
        if (this.abortTimer == 4 || this.abortTimer > 4) {
            loadingMessage = message[1];
        }
        this.store.dispatch(
            new ShowLoader({
                showLoader: true,
                message: loadingMessage,
            })
        );

        const state = getState();
        const activeModal = this.modalCtrl.getTop();

        return this.httpService.fetchOrderStatus(action.payload.orderRef, action.payload.bp).pipe(
            tap((response: any) => {
                if (response.orderStatus == "SUCCESSFUL") {
                    this.abortTimer = 0;
                    this.storage.get("activeOrder").then((order: any) => {
                        setTimeout(() => {
                            this.store.dispatch(new HideLoader({ hideLoader: false }));
                        }, 100);

                        if (activeModal !== null) this.modalCtrl.dismiss();

                        this.store.dispatch(
                            new ShowUserFeedback({
                                title: "Success!",
                                message: `You have successfully bought <br /> <span>${order.bundle.productDescription}</span>`,
                                icon: "okay-icon.svg",
                                redirect: "/home",
                                btnText: "no-btn",
                            })
                        );
                        this.storage.remove("activeOrder");
                        this.store.dispatch(new GetMsisdns());
                        this.store.dispatch(new ResetPrepaidState());
                    });
                } else if (response.orderStatus == "CREATED") {
                    this.abortTimer = this.abortTimer + 1;

                    if (this.abortTimer == 5 || this.abortTimer > 5) {
                        this.store.dispatch(new AbortOrder());
                        this.abortTimer = 0;
                    } else {
                        this.store.dispatch(new HideLoader({ hideLoader: false }));
                        setTimeout(() => {
                            this.store.dispatch(
                                new QueryPrepaidOrderStatus({
                                    orderRef: action.payload.orderRef,
                                    bp: action.payload.bp,
                                })
                            );
                        }, 2000);
                    }
                } else if (response.orderStatus == "FAILED") {
                    this.abortTimer = 0;
                    this.storage.remove("activeOrder");
                    if (activeModal !== null) this.modalCtrl.dismiss();
                    this.store.dispatch(new HideLoader({ hideLoader: false }));
                    this.store.dispatch(
                        new ShowUserFeedback({
                            isError: true,
                            title: "Oops!",
                            message: response.orderStatusReason,
                            btnText: "TRY AGAIN",
                            icon: "payments-error-icon.svg",
                            btnIcon: "assets/images/icons/arrow-left-white.svg",
                            link: "/tabs/top-up",
                            cancel: true,
                        })
                    );
                }
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                if (activeModal !== null) this.modalCtrl.dismiss();
                this.store.dispatch(new HideLoader({ hideLoader: false }));

                this.store.dispatch(
                    new ShowUserFeedback({
                        isError: true,
                        title: "Oops!",
                        message: errorResponse.error.message,
                        btnText: "TRY AGAIN",
                        icon: "payments-error-icon.svg",
                        btnIcon: "assets/images/icons/arrow-left-white.svg",
                        link: "/tabs/top-up",
                        cancel: true,
                    })
                );

                return of();
            })
        );
    }

    /**
     *
     * @param param0 Destroy current active order
     */
    @Action(DestroyPrepaidOrder)
    destroyOrder({ getState, setState }: StateContext<PrepaidBundleModel>) {
        const state = getState();

        setState({
            ...state,
            activeOrder: null,
        });
    }
    /**
     *
     * @param param0 Destroy current active order
     */
    @Action(AbortOrder)
    abortOrder({ getState, setState }: StateContext<PrepaidBundleModel>) {
        const state = getState();
        this.store.dispatch(new HideLoader({ hideLoader: false }));
        this.modalCtrl.dismiss();
        setState({
            ...state,
            activeOrder: null,
            selectedPaymentMethods: undefined,
        });

        this.store.dispatch(
            new ShowUserFeedback({
                isError: true,
                title: "Oops!",
                message: "We’re sorry, it looks like there was a technical error processing your order.",
                btnText: "TRY AGAIN",
                icon: "payments-error-icon.svg",
                btnIcon: "assets/images/icons/arrow-left-white.svg",
                link: "/tabs/top-up",
                cancel: true,
            })
        );
        //Just making sure there's no fun things

        this.store.dispatch(new ResetPrepaidState());
    }

    /**
     * Reset
     */
    @Action(ResetPrepaidState)
    resetPrepaidBundle({ getState, setState }: StateContext<PrepaidBundleModel>) {
        const state = getState();

        setState({
            ...state,
            selectedBundle: undefined,
            activeOrder: null,
            selectedPaymentMethods: {
                creditCard: undefined,
                smartShopperCard: undefined,
                redeemAmount: 0,
                amount: 0,
                payWithNewCard: false,
            },
        });
    }

    /**
     * Reset
     */
    @Action(CancelOrder)
    cancelPrepaidOrder({ getState, setState }: StateContext<PrepaidBundleModel>) {
        const state = getState();
        this.store.dispatch(new HideLoader({ hideLoader: false }));
        this.modalCtrl.dismiss();

        setState({
            ...state,
            selectedBundle: undefined,
            activeOrder: null,
            selectedPaymentMethods: {
                creditCard: undefined,
                smartShopperCard: undefined,
                redeemAmount: 0,
                amount: 0,
                payWithNewCard: false,
                saveNewCard: false,
            },
        });

        this.store.dispatch(
            new ShowUserFeedback({
                isError: true,
                title: "Oops!",
                message: "You have canceled your order.",
                btnText: "TRY AGAIN",
                icon: "payments-error-icon.svg",
                btnIcon: "assets/images/icons/arrow-left-white.svg",
                link: "/tabs/home",
                cancel: true,
            })
        );
    }
}
