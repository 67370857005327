export class TypeChecks {
  private constructor() {}
  static initialise() {}

  static getTypeAsString(value: any) {
    return Object.prototype.toString.call(value);
  }

  static isObject(value: any) {
    return Object.prototype.toString.call(value) === "[object Object]";
  }

  static isObjectAndNotEmpty(value: any) {
    if (!this.isObject(value)) {
      return false;
    }
    for (let key in value) {
      if (value.hasOwnProperty(key)) {
        return true;
      }
    }
    return false;
  }

  static isString(value: any) {
    return Object.prototype.toString.call(value) === "[object String]";
  }

  static isStringAndNotEmpty(value: any) {
    return (
      Object.prototype.toString.call(value) === "[object String]" &&
      value.trim() !== ""
    );
  }

  static isNumber(value: any) {
    return Object.prototype.toString.call(value) === "[object Number]";
  }

  static isNumberAndNotNaN(value: any) {
    return (
      Object.prototype.toString.call(value) === "[object Number]" &&
      !isNaN(value)
    );
  }

  static isBoolean(value: any) {
    return Object.prototype.toString.call(value) === "[object Boolean]";
  }

  static isArray(value: any) {
    return Object.prototype.toString.call(value) === "[object Array]";
  }

  static isArrayAndNotEmpty(value: any) {
    return (
      Object.prototype.toString.call(value) === "[object Array]" &&
      value.length > 0
    );
  }

  static isDate(value: any) {
    return Object.prototype.toString.call(value) === "[object Date]";
  }

  static isError(value: any) {
    return Object.prototype.toString.call(value) === "[object Error]";
  }

  static isFunction(value: any) {
    return Object.prototype.toString.call(value) === "[object Function]";
  }
}
TypeChecks.initialise();
