import { Syncable } from "./syncable";
import { DeltaSyncable } from "./delta-syncable";
import { TypeChecks as productTypeChecks } from "src/app/utilities/type-checks";

/**
 * PrepaidBundleDto model class
 * to check if inputs values have been tempted
 * or modified the originals values or types
 */
export class PrepaidBundleDto extends Syncable {
  maxAmount: Number | undefined;
  minAmount: Number | undefined;
  notes: any;
  price: Number | undefined;
  priceUnit: any;
  productDescription: String | undefined;
  productId: any;
  productName: any;
  productType: String | undefined;
  validDays: Number | undefined;

  constructor(
    maxAmount?: Number,
    minAmount?: Number,
    notes?: any,
    price?: Number,
    priceUnit?: any,
    productDescription?: String,
    productId?: any,
    productName?: any,
    productType?: String,
    validDays?: Number
  ) {
    super();
    maxAmount !== void 0 && (this.maxAmount = maxAmount);
    minAmount !== void 0 && (this.minAmount = minAmount);
    notes !== void 0 && (this.notes = notes);
    price !== void 0 && (this.price = price);
    priceUnit !== void 0 && (this.priceUnit = priceUnit);
    productDescription !== void 0 &&
      (this.productDescription = productDescription);
    productId !== void 0 && (this.productId = productId);
    productName !== void 0 && (this.productName = productName);
    productType !== void 0 && (this.productType = productType);
    validDays !== void 0 && (this.validDays = validDays);
  }

  override equals(other: PrepaidBundleDto): boolean {
    return (
      super.equals(other) &&
      this.maxAmount === other.maxAmount &&
      this.minAmount === other.minAmount &&
      this.notes === other.notes &&
      this.price === other.price &&
      this.priceUnit === other.priceUnit &&
      this.productDescription === other.productDescription &&
      this.productId === other.productId &&
      this.productName === other.productName &&
      this.productType === other.productType &&
      this.validDays === other.validDays
    );
  }

  get isValid(): boolean {
    return (
      productTypeChecks.isNumberAndNotNaN(this.price) &&
      [this.productId, this.productType, this.validDays].every((prop) => {
        return productTypeChecks.isStringAndNotEmpty(prop);
      })
    );
  }

  coerceproductTypes() {
    if (productTypeChecks.isStringAndNotEmpty(this.productId)) {
      try {
        this.price = parseFloat(<string>(<any>this.price));
      } catch (error) {}
    }
  }

  override assign(other: PrepaidBundleDto, deepAssign = true): PrepaidBundleDto {
    deepAssign && super.assign(other);

    other.maxAmount !== void 0 && (this.maxAmount = other.maxAmount);
    other.minAmount !== void 0 && (this.minAmount = other.minAmount);
    other.notes !== void 0 && (this.notes = other.notes);
    other.price !== void 0 && (this.price = other.price);
    other.priceUnit !== void 0 && (this.priceUnit = other.priceUnit);
    other.productDescription !== void 0 &&
      (this.productDescription = other.productDescription);
    other.productId !== void 0 && (this.productId = other.productId);
    other.productName !== void 0 && (this.productName = other.productName);
    other.productType !== void 0 && (this.productType = other.productType);
    other.validDays !== void 0 && (this.validDays = other.validDays);
    return this;
  }

  getRestFields() {
    return this.assign.call({}, this, false);
  }

  protected override deconstruct(): object {
    return {
      p: super.deconstruct(),
      i: {
        maxAmount: this.maxAmount,
        minAmount: this.minAmount,
        notes: this.notes,
        price: this.price,
        priceUnit: this.priceUnit,
        productDescription: this.productDescription,
        productId: this.productId,
        productName: this.productName,
        productType: this.productType,
        validDays: this.validDays,
      },
    };
  }

  protected override reconstruct(obj: any): PrepaidBundleDto {
    const { p, i } = obj;
    super.reconstruct(p);

    this.maxAmount = i.maxAmount;
    this.minAmount = i.minAmount;
    this.notes = i.notes;
    this.price = i.price;
    this.priceUnit = i.priceUnit;
    this.productDescription = i.productDescription;
    this.productId = i.productId;
    this.productName = i.productName;
    this.productType = i.productType;
    this.validDays = i.validDays;

    this.coerceproductTypes();
    return this;
  }
}

export class PrepaidBundlesDto extends DeltaSyncable {
  override syncableArray = new Array<PrepaidBundleDto>(0);

  getSyncable() {
    return new PrepaidBundleDto();
  }

  override assign(other: PrepaidBundlesDto): PrepaidBundlesDto {
    super.assign(other);
    return this;
  }

  protected override deconstruct(): object {
    return super.deconstruct();
  }

  protected override reconstruct(obj: any): PrepaidBundlesDto {
    super.reconstruct(obj, new PrepaidBundleDto());
    return this;
  }

  fromKey(productId: string): PrepaidBundleDto | null {
    if (!productId) return null;
    // @ts-ignore
    return this.syncableArray
      .find((PrepaidBundle) => PrepaidBundle.productId === productId)
      .getRestFields();
  }
}
