import { State } from "src/app/state/state";
import { TypeChecks } from "src/app/utilities";

export enum AuthStatus {
  Offline,
  Online,
  IsSynchronised,
  HasBasicCredentials,
  HasTokenCredentials,
  Authenticated,
}

export class AuthBasicCredentials extends State {
  constructor(
    public readonly username?: string,
    public readonly password?: string
  ) {
    super();
  }

  static fromJson(json: string) {
    const obj = JSON.parse(json);
    return new AuthBasicCredentials(obj.username, obj.password);
  }

  equals(other: AuthBasicCredentials): boolean {
    return this.username === other.username && this.password === other.password;
  }

  clone(): AuthBasicCredentials {
    return new AuthBasicCredentials(this.username, this.password);
  }

  get isValid(): boolean {
    return [this.username, this.password].every((prop) =>
      TypeChecks.isStringAndNotEmpty(prop)
    );
  }
}

export class AuthTokenCredentials extends State {
  constructor(
    public readonly guid?: string,
    public readonly accessToken?: string,
    public readonly accessTokenIssueTime?: Date,
    public readonly accessTokenExpiryTime?: Date,
    public readonly refreshToken?: string,
    public readonly refreshTokenExpiryTime?: Date
  ) {
    super();
  }

  static fromJson(json: string) {
    const obj = JSON.parse(json);
    return new AuthTokenCredentials(
      obj.guid,
      obj.accessToken,
      new Date(obj.accessTokenIssueTime),
      new Date(obj.accessTokenExpiryTime),
      obj.refreshToken,
      new Date(obj.refreshTokenExpiryTime)
    );
  }

  equals(other: AuthTokenCredentials): boolean {
    const isEqualAccessTokenIssueTime =
      this.accessTokenIssueTime === other.accessTokenIssueTime ||
      (this.accessTokenIssueTime &&
        other.accessTokenIssueTime &&
        this.accessTokenIssueTime.getTime() ===
          other.accessTokenIssueTime.getTime());
    const isEqualAccessTokenExpiryTime =
      this.accessTokenExpiryTime === other.accessTokenExpiryTime ||
      (this.accessTokenExpiryTime &&
        other.accessTokenExpiryTime &&
        this.accessTokenExpiryTime.getTime() ===
          other.accessTokenExpiryTime.getTime());
    const isEqualRefreshTokenExpiry =
      this.refreshTokenExpiryTime === other.refreshTokenExpiryTime ||
      (this.refreshTokenExpiryTime &&
        other.refreshTokenExpiryTime &&
        this.refreshTokenExpiryTime.getTime() ===
          other.refreshTokenExpiryTime.getTime());
    return <boolean>(
      this.guid === other.guid &&
      this.accessToken === other.accessToken &&
      isEqualAccessTokenIssueTime &&
      isEqualAccessTokenExpiryTime &&
      this.refreshToken === other.refreshToken &&
      isEqualRefreshTokenExpiry
    );
  }

  clone(): AuthTokenCredentials {
    return new AuthTokenCredentials(
      this.guid,
      this.accessToken,
      this.accessTokenIssueTime
        ? new Date(this.accessTokenIssueTime.getTime())
        : void 0,
      this.accessTokenExpiryTime
        ? new Date(this.accessTokenExpiryTime.getTime())
        : void 0,
      this.refreshToken,
      this.refreshTokenExpiryTime
        ? new Date(this.refreshTokenExpiryTime.getTime())
        : void 0
    );
  }

  get isValid(): boolean {
    return (
      [this.guid, this.accessToken, this.refreshToken].every((prop) =>
        TypeChecks.isStringAndNotEmpty(prop)
      ) &&
      [
        this.accessTokenIssueTime,
        this.accessTokenExpiryTime,
        this.refreshTokenExpiryTime,
      ].every((prop) => TypeChecks.isDate(prop))
    );
  }

  get canAuthorise(): boolean {

    return (
      this.isValid &&
      // @ts-ignore
      new Date().getTime() < this.refreshTokenExpiryTime.getTime()
    );
  }
}
