import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { EMPTY, from, Observable, of } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { Select, Store } from "@ngxs/store";

import { AuthState } from "src/app/state/auth/auth.state";
import { NetworkService } from "src/app/services/network.service";
import { CmsApiService } from "src/app/services/cms-api.service";

import { environment as env } from "src/environments/environment";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    @Select(AuthState.isAuthenticated) authenticated$!: Observable<boolean>;
    constructor(private store: Store, private network: NetworkService, private cmsAuthService: CmsApiService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //Check network status before all API calls
        if (!this.network.checkNetworkStatus()) {
            return EMPTY;
        }
        //Add Bearer
        // @ts-ignore
        this.authenticated$.subscribe((status: any) => {
            if (typeof status !== "undefined" && status !== null && status !== false) {
                if (!request.url.includes("oauth") && request.body && (request.url.includes("token") || request.url.includes("env1/token"))) {
                    const grant = request.body.toString().split("&")[0];
                    if (
                        grant === "grant_type=refresh_token" ||
                        grant === "grant_type=urn:picknpay:params:oauth:grant-type:otp"
                    ) {
                        return next.handle(request);
                    }
                }

                if (request.body && request.url.includes("auth/onetimepasswordrequests")) {
                    return next.handle(request);
                }

                if (request.body && request.url.includes("mvno/accountmanagementrequests")) {
                    return next.handle(request);
                }

                if (status && (request.url.includes(env.S3_DEV_QA_CDN) || request.url.includes(env.S3_PROD_CDN))) {
                    return next.handle(request);
                } else if (status) {
                    this.store.select(AuthState.accessToken).subscribe((accessToken) => {
                        if (typeof accessToken !== "undefined" && accessToken !== null) {
                            request = request.clone({
                                setHeaders: {
                                    Authorization: `Bearer ${accessToken}`,
                                },
                            });
                        }
                    });
                }
            }
        });

        return next.handle(request);
    }
}
