import { State, Action, StateContext, Selector, Store } from "@ngxs/store";
import { tap, catchError } from "rxjs/operators";

import {
    GetBP,
    GetMsisdns,
    GetSmartShopperBlance,
    SetSelectedMsisdn,
    SyncBusinessPartner,
    ResetPassword,
    TransferReward,
    AccountLinkOptions,
    GetMsisdnBalance,
    AccountLinkBp,
    TransferData,
    ResetBusinessPartner,
    UpdateMsisdnAlias,
    ClearApiFunctionCompleted,
    GetRewardDataTransferAmounts,
    GetDataTransferAmounts,
    GetAirtimeConvertAmounts,
    GetRewardInfo,
    GetBusinessPartnerSavedPaymentCards,
    GetRewardInfoCMS,
    ClearSavedCreditCards,
    AccountLinkMsisdnRewards,
    GetPromoInfoCMS,
} from "./business-partner.actions";

import { Injectable, NgZone } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";
import { ToasterMessage } from "src/app/shared";
import { PnPHttpService } from "src/app/services/pnp-http.service";
import { BusinessPartnerMainModel } from "src/app/models/business-partner-state.model";
import { BusinessPartner, SmartShopperCard } from "src/app/models";
import { Msisdn } from "src/app/models/msisdn";
import { Logout, RefreshToken } from "../auth/auth.actions";
import { NavigationExtras, Router } from "@angular/router";
import { SecureStorageService } from "src/app/services/secure-storage.service";
import { HideLoader, ShowLoader } from "../loader/loader.actions";
import { AlertController } from "@ionic/angular";
import { CmsApiService } from "src/app/services/cms-api.service";
import { PaymentCardDto } from "src/app/domain/syncable/payment-cards";

@State<BusinessPartnerMainModel>({
    name: "businessPartner",
    defaults: {
        businessPartnerMain: undefined,
        smartShopperCard: undefined,
        msisdns: [],
        primaryMsisdn: null,
        selectedMsisdn: null,
        accountLinkOptions: null,
        aPIFunctionCompleted: null,
        primaryMsisdnRewardInfo: null,
        paymentCards: [],
    },
})
@Injectable()
export class BusinessPartnerState {
    @Selector()
    static getBusinessPartnerState(state: BusinessPartnerMainModel) {
        return state.businessPartnerMain;
    }

    @Selector()
    static getSmartShopperState(state: BusinessPartnerMainModel) {
        return state.smartShopperCard;
    }

    @Selector()
    static getMsisdnsState(state: BusinessPartnerMainModel) {
        return state.msisdns;
    }

    @Selector()
    static getSelectedMsisdnsState(state: BusinessPartnerMainModel) {
        return state.selectedMsisdn;
    }

    @Selector()
    static getApiFunctionCompleted(state: BusinessPartnerMainModel) {
        return state.aPIFunctionCompleted;
    }

    @Selector()
    static getPrimaryMsisdnRewardInfo(state: BusinessPartnerMainModel) {
        return state.primaryMsisdnRewardInfo;
    }

    @Selector()
    static getPaymentCards(state: BusinessPartnerMainModel) {
        return state.paymentCards?.length ?? state.paymentCards;
    }

    constructor(
        private zone: NgZone,
        private store: Store,
        private toastMsg: ToasterMessage,
        private storage: SecureStorageService,
        private pnpHttp: PnPHttpService,
        private router: Router,
        public alertController: AlertController,
        private cmsAuthService: CmsApiService
    ) {}

    //Todo: need to refactor this state

    @Action(GetBP)
    GetBP({ getState, setState }: StateContext<BusinessPartnerMainModel>, action: GetBP) {
        // @ts-ignore
        return this.pnpHttp.getBusinessPartner(action?.token, action?.guid).pipe(
            tap((response: any) => {
                console.log({ bp: response });
                const state = getState();
                setState({
                    ...state,
                    businessPartnerMain: response,
                });
                this.setBusinessPartnerHandler();
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                this.handleError(errorResponse);
                return of();
            })
        );
    }

    @Action(GetSmartShopperBlance)
    GetSmartShopperBlance(
        { getState, setState }: StateContext<BusinessPartnerMainModel>,
        action: GetSmartShopperBlance
    ) {
        const state = getState();
        if (state.businessPartnerMain?.smartShopperAccountNumber != null) {
            return this.pnpHttp.getSmartShopperAccount(action?.token).pipe(
                tap((response: any) => {
                    const state = getState();
                    setState({
                        ...state,
                        smartShopperCard: response,
                    });
                    this.setSmartShopperCardHandler();
                }),
                catchError((errorResponse: HttpErrorResponse) => {
                    this.handleError(errorResponse);
                    return of();
                })
            );
        } else {
            const state = getState();
            setState({
                ...state,
                smartShopperCard: undefined,
            });
            return;
        }
    }

    @Action(GetMsisdns)
    GetMsisdns({ getState, setState }: StateContext<BusinessPartnerMainModel>, action: GetMsisdns) {
        const state = getState();
        if (
            state.businessPartnerMain?.mvnoManagedAccounts != null &&
            state.businessPartnerMain?.mvnoManagedAccounts.length > 0
        ) {
            let resp = null;
            let msisdnArray: {
                msisdn: any;
                displayMsisdn: any;
                accountNumber: any;
                isPrimary: any;
                alias: any;
                ricaDateTime: any;
                ricaDateTimeDisplay: string;
            }[] = [];
            state.businessPartnerMain.mvnoManagedAccounts.forEach((mvnoManagedAccount: any) => {
                resp = this.pnpHttp.getMsisdns(state.businessPartnerMain?.id, mvnoManagedAccount).pipe(
                    tap((response: any) => {
                        let primaryMsisdnObject = null;
                        if (response.msisdns.length > 0) {
                            response.msisdns.forEach((msisdn: any) => {
                                if (msisdn.primaryMsisdn == true) {
                                    primaryMsisdnObject = {
                                        msisdn: msisdn.msisdn,
                                        displayMsisdn: this.formatMsisdn(msisdn.msisdn),
                                        accountNumber: response.accountNumber,
                                        isPrimary: msisdn.primaryMsisdn,
                                        alias: msisdn.alias,
                                        ricaDateTime: msisdn.ricaDateTime,
                                        ricaDateTimeDisplay: this.formatDate(msisdn.ricaDateTime),
                                    };
                                }

                                msisdnArray.push({
                                    msisdn: msisdn.msisdn,
                                    displayMsisdn: this.formatMsisdn(msisdn.msisdn),
                                    accountNumber: response.accountNumber,
                                    isPrimary: msisdn.primaryMsisdn,
                                    alias: msisdn.alias,
                                    ricaDateTime: msisdn.ricaDateTime,
                                    ricaDateTimeDisplay: this.formatDate(msisdn.ricaDateTime),
                                });
                            });
                        }
                        //sort msisdn to always start with primary
                        msisdnArray.sort((a, b) => {
                            return a.isPrimary === b.isPrimary ? 0 : a.isPrimary ? -1 : 1;
                        });

                        const state = getState();
                        setState({
                            ...state,
                            msisdns: msisdnArray,
                            primaryMsisdn: primaryMsisdnObject,
                        });

                        //Get reward info for the primary msisdn
                        if (primaryMsisdnObject) {
                            this.store.dispatch(new GetRewardInfo());
                        }

                        let theSelectedMsisdn = state.selectedMsisdn;
                        this.checkSelectedMsisdn(msisdnArray, primaryMsisdnObject, theSelectedMsisdn);
                        this.setMsisdnsHandler();
                        this.setPrimaryMsisdnsHandler();
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        this.handleError(errorResponse);
                        return of();
                    })
                );
            });
            return resp;
        } else {
            const state = getState();
            setState({
                ...state,
                msisdns: [],
            });
            return;
        }
    }

    @Action(GetMsisdnBalance)
    GetMsisdnBalance({ getState, setState }: StateContext<BusinessPartnerMainModel>, action: GetMsisdnBalance) {
        const state = getState();
        if (state.businessPartnerMain && state.businessPartnerMain.id) {
            const payload = {
                bpid: state.businessPartnerMain.id,
                mvnoAccountNumber: action.selectedMsisdn.accountNumber,
                msisdn: action.selectedMsisdn.msisdn,
            };

            let primaryMsisdn!: string;
            if (state.businessPartnerMain.primaryMsisdn) {
                primaryMsisdn = state.businessPartnerMain.primaryMsisdn;
            }

            let selectedMsisdn!: Msisdn;
            if (state.selectedMsisdn) {
                selectedMsisdn = state.selectedMsisdn;
            }
            return this.pnpHttp.getMsisdnBalance(payload).pipe(
                tap((response: any) => {
                    action.selectedMsisdn;
                    let tempMsisdn = new Msisdn();
                    tempMsisdn.balances = response.balances;
                    let finalMsisdn = Object.assign(tempMsisdn, action.selectedMsisdn);
                    finalMsisdn.balances = response.balances;
                    const state = getState();
                    if (finalMsisdn.msisdn == primaryMsisdn) {
                        if (finalMsisdn.msisdn == selectedMsisdn?.msisdn) {
                            setState({
                                ...state,
                                primaryMsisdn: finalMsisdn,
                                selectedMsisdn: finalMsisdn,
                            });
                            this.setPrimaryMsisdnsHandler();
                            this.setSelectedMsisdnHandler();
                        } else {
                            setState({
                                ...state,
                                primaryMsisdn: finalMsisdn,
                            });
                            this.setPrimaryMsisdnsHandler();
                        }
                    } else {
                        setState({
                            ...state,
                            selectedMsisdn: finalMsisdn,
                        });
                        this.setSelectedMsisdnHandler();
                    }
                }),
                catchError((errorResponse: HttpErrorResponse) => {
                    this.handleError(errorResponse);
                    return of();
                })
            );
        }

        return;
    }

    @Action(GetRewardInfo)
    GetRewardInfo({ getState, setState }: StateContext<BusinessPartnerMainModel>, action: GetRewardInfo) {
        const state = getState();
        if (state.businessPartnerMain && state.businessPartnerMain.id && state.primaryMsisdn) {
            let primaryMsisdn = null;
            primaryMsisdn = state.primaryMsisdn;
            let payload = {
                bpid: state.businessPartnerMain.id,
                mvnoAccountNumber: primaryMsisdn.accountNumber,
                msisdn: primaryMsisdn.msisdn,
            };

            return this.pnpHttp.getRewardInfo(payload).pipe(
                tap((response: any) => {
                    setState({
                        ...state,
                        primaryMsisdnRewardInfo: response,
                    });
                    this.setRewardInfoHandler();
                }),
                catchError((errorResponse: HttpErrorResponse) => {
                    this.handleError(errorResponse);
                    return of();
                })
            );
        }

        return;
    }

    @Action(AccountLinkOptions)
    AccountLinkOptions({ getState, setState }: StateContext<BusinessPartnerMainModel>, action: AccountLinkOptions) {
        this.store.dispatch(
            new ShowLoader({
                showLoader: true,
                message: "",
            })
        );
        return this.pnpHttp.accountLinkOptions(action?.id_passport, action?.value).pipe(
            tap((response: any) => {
                this.store.dispatch(new HideLoader({ hideLoader: false }));

                //No account found
                if (response.mvnoAccounts.length < 1) {
                    let feedback = {
                        title: "Number not found",
                        icon: "user-search.svg",
                        message:
                            "We could not find any active cellphone numbers using the ID or Passport. Please ensure that your SIM card is RICA'd or contact customer care for assistance.",
                        error: true,
                    };
                    this.handleSuccessResponse(feedback);
                }

                const state = getState();
                setState({
                    ...state,
                    accountLinkOptions: response,
                });
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                this.store.dispatch(new HideLoader({ hideLoader: false }));
                this.handleError(errorResponse);
                return of();
            })
        );
    }

    @Action(TransferData)
    TransferData({ getState, setState }: StateContext<BusinessPartnerMainModel>, action: TransferData) {
        this.store.dispatch(
            new ShowLoader({
                showLoader: true,
                message: "",
            })
        );
        return this.pnpHttp.transferData(action?.payload).pipe(
            tap((response: any) => {
                this.store.dispatch(new GetMsisdns()).subscribe(() => {
                    this.store.dispatch(new HideLoader({ hideLoader: false }));
                    let feedback = {
                        title: "Success!",
                        message: `You have successfully transferred <span>${
                            action.payload.displayAmount
                        } </span> to <br/> <span> ${this.formatMsisdn(action.payload.recipient.toString())} </span>`,
                        redirect: "/home",
                        btnText: "no-btn",
                    };
                    this.handleSuccessResponse(feedback);
                });
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                this.store.dispatch(new HideLoader({ hideLoader: false }));
                this.handleError(errorResponse);

                return of();
            })
        );
    }

    @Action(UpdateMsisdnAlias)
    UpdateMsisdnAlias({ getState, setState }: StateContext<BusinessPartnerMainModel>, action: UpdateMsisdnAlias) {
        this.store.dispatch(
            new ShowLoader({
                showLoader: true,
                message: "",
            })
        );
        return this.pnpHttp.updateMsisdnAlias(action?.payload).pipe(
            tap((response: any) => {
                this.store.dispatch(new GetMsisdns()).subscribe(() => {
                    this.store.dispatch(new HideLoader({ hideLoader: false }));

                    const state = getState();
                    setState({
                        ...state,
                        aPIFunctionCompleted: true,
                    });
                });
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                this.store.dispatch(new HideLoader({ hideLoader: false }));
                this.presentErrorAlert("There was an error saving your details. Please try again.");
                return of();
            })
        );
    }

    @Action(AccountLinkBp)
    AccountLinkBp({ getState, setState }: StateContext<BusinessPartnerMainModel>, action: AccountLinkBp) {
        this.store.dispatch(
            new ShowLoader({
                showLoader: true,
                message: "Linking account",
            })
        );
        return this.pnpHttp.accountLinkToBp(action.payload).pipe(
            tap((response: any) => {
                this.store.dispatch(new GetBP()).subscribe(() => {
                    this.store.dispatch(new GetMsisdns()).subscribe(() => {
                        this.store.dispatch(new HideLoader({ hideLoader: false }));
                        let feedback = {
                            message: "Welcome to the PnP Mobile App.",
                            messageLine2: "Continue to the dashboard.",
                            redirect: "/home",
                            btnText: "no-btn",
                        };
                        this.handleSuccessResponse(feedback);
                    });
                });
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                this.store.dispatch(new HideLoader({ hideLoader: false }));
                this.handleError(errorResponse);
                return of();
            })
        );
    }

    @Action(AccountLinkMsisdnRewards)
    AccountLinkMsisdnRewards(
        { getState, setState }: StateContext<BusinessPartnerMainModel>,
        action: AccountLinkMsisdnRewards
    ) {
        this.store.dispatch(
            new ShowLoader({
                showLoader: true,
                message: "Linking for rewards",
            })
        );
        return this.pnpHttp.accountLinkPrimary(action.payload).pipe(
            tap((response: any) => {
                let linkDateValue = new Date().toDateString();
                const ld = linkDateValue.replace(/\s/g, "");
                let simLinked = {
                    ld: ld,
                    bp: action.payload.businessPartnerId,
                };
                this.storage.set("simLinked", simLinked).then(() => {
                    this.storage.set("simLinkedMsisdn", action.payload.msisdn).then(() => {
                        this.store.dispatch(new HideLoader({ hideLoader: false }));
                    });
                });
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                this.store.dispatch(new HideLoader({ hideLoader: false }));
                this.handleError(errorResponse);
                return of();
            })
        );
    }

    @Action(ResetPassword)
    ResetPassword({ getState, setState }: StateContext<BusinessPartnerMainModel>, action: ResetPassword) {
        this.store.dispatch(
            new ShowLoader({
                showLoader: true,
                message: "",
            })
        );
        return this.pnpHttp.resetPassword(action?.username).pipe(
            tap((response: any) => {
                this.store.dispatch(new HideLoader({ hideLoader: false }));
                let feedback = {
                    title: "Check your mobile",
                    link: "auth/login",
                    message: "Click the link in the SMS we just sent to *** *** 7743",
                    icon: "mobile.svg",
                    btnText: "Back to login",
                };
                this.handleSuccessResponse(feedback);
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                this.store.dispatch(new HideLoader({ hideLoader: false }));
                //TODO uncomment below and remove success feedback below
                //this.handleError(errorResponse);
                let feedback = {
                    title: "Check your mobile",
                    link: "auth/login",
                    message: "Click the link in the SMS we just sent to *** *** 7743",
                    icon: "mobile.svg",
                    btnText: "Back to login",
                };
                this.handleSuccessResponse(feedback);

                return of();
            })
        );
    }

    @Action(TransferReward)
    TransferReward({ getState, setState }: StateContext<BusinessPartnerMainModel>, action: TransferReward) {
        this.store.dispatch(
            new ShowLoader({
                showLoader: true,
                message: "",
            })
        );
        return this.pnpHttp.transferRewards(action?.value).pipe(
            tap((response: any) => {
                let feedback = {
                    title: "Success!",
                    message: "You have successfully transfered 100MB to 072 272 9854",
                    redirect: "/home",
                    btnText: "no-btn",
                };
                this.handleSuccessResponse(feedback);

                this.store.dispatch(new HideLoader({ hideLoader: false }));
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                //TODO uncomment below and remove success feedback below
                //this.handleError(errorResponse);
                /*
        let feedback = {
            isError: true,
            title: 'Oops!',
            link: 'rewards/transfer',
            message: 'There was an error transferring your instant data rewards. ',
            messageLine2: 'Please try again.',
            icon: 'general-error.svg',
            btnText: 'Back to transfer'
        };
        */
                let feedback = {
                    title: "Success!",
                    message: "You have successfully transfered 100MB to 072 272 9854",
                    redirect: "/home",
                    btnText: "no-btn",
                };
                this.handleSuccessResponse(feedback);

                this.store.dispatch(new HideLoader({ hideLoader: false }));

                return "asd";
            })
        );
    }

    @Action(SetSelectedMsisdn)
    SetSelectedMsisdn({ getState, setState }: StateContext<BusinessPartnerMainModel>, action: SetSelectedMsisdn) {
        this.store.dispatch(
            new ShowLoader({
                showLoader: true,
                message: "",
            })
        );
        const state = getState();
        let primaryMsisdn: string | null | undefined = null;
        if (state.businessPartnerMain?.primaryMsisdn) {
            primaryMsisdn = state.businessPartnerMain?.primaryMsisdn;
        }
        let selectedMsisdn = null;
        if (state.selectedMsisdn) {
            selectedMsisdn = state.selectedMsisdn;
        }

        if (state.businessPartnerMain && state.businessPartnerMain.id) {
            let payload = {
                bpid: state.businessPartnerMain.id,
                mvnoAccountNumber: action.selectedMsisdn.accountNumber,
                msisdn: action.selectedMsisdn.msisdn,
            };

            return this.pnpHttp.getMsisdnBalance(payload).pipe(
                tap((response: any) => {
                    action.selectedMsisdn;
                    let tempMsisdn = new Msisdn();
                    tempMsisdn.balances = response.balances;
                    let finalMsisdn = Object.assign(tempMsisdn, action.selectedMsisdn);
                    finalMsisdn.balances = response.balances;
                    const state = getState();

                    if (finalMsisdn.msisdn == primaryMsisdn) {
                        setState({
                            ...state,
                            primaryMsisdn: finalMsisdn,
                            selectedMsisdn: finalMsisdn,
                        });
                        this.setPrimaryMsisdnsHandler();
                        this.setSelectedMsisdnHandler();
                    } else {
                        setState({
                            ...state,
                            selectedMsisdn: finalMsisdn,
                        });
                        this.setSelectedMsisdnHandler();
                    }
                    this.store.dispatch(new HideLoader({ hideLoader: false }));
                }),
                catchError((errorResponse: HttpErrorResponse) => {
                    this.store.dispatch(new HideLoader({ hideLoader: false }));
                    //Log the error response
                    // //console.log(errorResponse);
                    this.handleError(errorResponse);
                    return of();
                })
            );
        }

        return;
    }

    @Action(GetRewardDataTransferAmounts)
    getRewardDataTransferAmounts(
        { setState, getState }: StateContext<BusinessPartnerMainModel>,
        action: GetRewardDataTransferAmounts
    ) {
        this.store.dispatch(
            new ShowLoader({
                showLoader: true,
                message: "",
            })
        );
        return this.cmsAuthService.getTransferAmountsRewardData().pipe(
            tap((response: any) => {
                const { items } = response;
                if (items.length)
                    this.storage.set("transfer-rewarddata-amounts", response.data.items).then(() => {
                        const state = getState();
                        setState({
                            ...state,
                            aPIFunctionCompleted: true,
                        });
                    });

                setTimeout(() => this.store.dispatch(new HideLoader({ hideLoader: false })), 200);
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                this.store.dispatch(new HideLoader({ hideLoader: false }));
                //Log the error response
                //console.log(errorResponse);
                this.handleError(errorResponse);
                return of();
            })
        );
    }

    @Action(GetRewardInfoCMS)
    GetRewardInfoCMS({ setState, getState }: StateContext<BusinessPartnerMainModel>, action: GetRewardInfoCMS) {
        if (action.showLoader) {
            this.store.dispatch(
                new ShowLoader({
                    showLoader: true,
                    message: "",
                })
            );
        }

        return this.cmsAuthService.getRewardInfo().pipe(
            tap((response: any) => {
                if (response) {
                    this.storage.set("reward-tier-info", response).then(() => {
                        const state = getState();
                        setState({
                            ...state,
                            aPIFunctionCompleted: true,
                        });
                    });
                }
                if (action.showLoader) {
                    setTimeout(() => {
                        this.store.dispatch(new HideLoader({ hideLoader: false }));
                    }, 200);
                }
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                this.store.dispatch(new HideLoader({ hideLoader: false }));
                //Log error response
                // //console.log(errorResponse);
                this.handleError(errorResponse);
                return of();
            })
        );
    }

    @Action(GetPromoInfoCMS)
    GetPromoInfoCMS({ setState, getState }: StateContext<BusinessPartnerMainModel>, action: GetPromoInfoCMS) {
        return this.cmsAuthService.getPromoInfo().pipe(
            tap((response: any) => {
                const { items } = response;
                if (items) {
                    this.storage.set("promo-cms-data", items).then(() => {
                        const state = getState();
                        setState({
                            ...state,
                            aPIFunctionCompleted: true,
                        });
                    });
                }
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                this.store.dispatch(new HideLoader({ hideLoader: false }));
                //Log error response
                // //console.log(errorResponse);
                this.handleError(errorResponse);
                return of();
            })
        );
    }

    @Action(GetDataTransferAmounts)
    getDataTransferAmounts(
        { setState, getState }: StateContext<BusinessPartnerMainModel>,
        action: GetDataTransferAmounts
    ) {
        // this.store.dispatch(
        //   new ShowLoader({
        //     showLoader: true,
        //     message: "",
        //   })
        // );
        return this.cmsAuthService.getTransferAmountsData().pipe(
            tap((response: any) => {
                const { items } = response;
                if (items.length)
                    this.storage.set("transfer-data-amounts", items).then(() => {
                        const state = getState();
                        setState({
                            ...state,
                            aPIFunctionCompleted: true,
                        });
                    });
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                this.handleError(errorResponse);
                return of();
            })
        );
    }

    @Action(GetAirtimeConvertAmounts)
    getAirtimeConvertAmounts(
        { setState, getState }: StateContext<BusinessPartnerMainModel>,
        action: GetAirtimeConvertAmounts
    ) {
        /* Commented as we calling this on top-up page
    this.store.dispatch(
      new ShowLoader({
        showLoader: true,
        message: "",
      })
    );*/
        return this.cmsAuthService.getAirtimeConvertAmounts().pipe(
            tap((response: any) => {
                const { items } = response;
                if (items.length)
                    this.storage.set("airtime-convert-amounts", items).then(() => {
                        const state = getState();
                        setState({
                            ...state,
                            aPIFunctionCompleted: true,
                        });
                    });
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                this.handleError(errorResponse);
                return of();
            })
        );
    }

    @Action(SyncBusinessPartner)
    syncBusinessPartner({ setState, getState }: StateContext<BusinessPartnerMainModel>, action: SyncBusinessPartner) {
        const state = getState();
        setState({
            ...state,
            businessPartnerMain: action.businessPartner,
            smartShopperCard: action.smartShopperCard,
            msisdns: action.msisdns,
            selectedMsisdn: action.selectedMsisdn,
            primaryMsisdn: action.primaryMsisdn,
            primaryMsisdnRewardInfo: action.primaryMsisdnRewardInfo,
        });
        return of();
    }

    @Action(ClearApiFunctionCompleted)
    ClearApiFunctionCompleted(
        { setState, getState }: StateContext<BusinessPartnerMainModel>,
        action: SyncBusinessPartner
    ) {
        const state = getState();
        setState({
            ...state,
            aPIFunctionCompleted: null,
        });
        return of();
    }

    @Action(ResetBusinessPartner)
    resetBusinessPartner({ setState, getState }: StateContext<BusinessPartnerMainModel>, action: SyncBusinessPartner) {
        const state = getState();

        setState({
            ...state,
            businessPartnerMain: undefined,
            smartShopperCard: undefined,
            msisdns: [],
            selectedMsisdn: null,
            primaryMsisdn: null,
            aPIFunctionCompleted: null,
            primaryMsisdnRewardInfo: null,
        });

        return of();
    }

    @Action(GetBusinessPartnerSavedPaymentCards)
    getSavedPaymentCards(
        { setState, getState }: StateContext<BusinessPartnerMainModel>,
        action: GetBusinessPartnerSavedPaymentCards
    ) {
        return this.pnpHttp.fetchSavedBankCards(action.payload.businessPartnerId).pipe(
            tap((response: any) => {
                let paymentCards: PaymentCardDto[] = [];

                const state = getState();

                if (response.length > 0) {
                    response.forEach((card: PaymentCardDto) => {
                        paymentCards.push(
                            new PaymentCardDto({
                                id: card.id,
                                cardNumber: card.cardNumber,
                                cardHolder: card.cardHolder,
                                cardType: card.cardType,
                                token: card.token,
                                bankImageUrl: card.bankImageUrl,
                                cardImageUrl: card.cardImageUrl,
                                expirationYear: card.expirationYear,
                                expirationMonth: card.expirationMonth,
                            })
                        );
                    });
                    setState({
                        ...state,
                        paymentCards: paymentCards,
                    });
                }

                return of();
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                if (errorResponse.status === 500 && errorResponse.error.message === "Unexpected error")
                    this.toastMsg.showToaster({
                        message: "Error: Unexpected error occurred while fetching saved payments cards.",
                        cssClass: "toast-error",
                    });
                else this.handleError(errorResponse);
                return of();
            })
        );
    }

    @Action(ClearSavedCreditCards)
    clearCards(
        { setState, getState }: StateContext<BusinessPartnerMainModel>,
        action: GetBusinessPartnerSavedPaymentCards
    ) {
        const state = getState();

        setState({
            ...state,
            businessPartnerMain: undefined,
            smartShopperCard: undefined,
            msisdns: [],
            primaryMsisdn: null,
            selectedMsisdn: null,
            accountLinkOptions: null,
            aPIFunctionCompleted: null,
            primaryMsisdnRewardInfo: null,
            paymentCards: [],
        });
    }
    private checkSelectedMsisdn(msisdns: any[], primaryMsisdn: Msisdn | null, selectedMsisdn: Msisdn | null) {
        //If there is only one msisdn then is will be the selected msisdn
        //console.log("checking the selected msisdn");
        if (msisdns.length == 1) {
            this.store.dispatch(new SetSelectedMsisdn(msisdns[0]));
        } else {
            //THIS NEEDS TO BE NOT A SUBSCTION

            if (selectedMsisdn == null) {
                //if there is NO selected msisdn in storage then we must select the first one
                if (primaryMsisdn) {
                    this.store.dispatch(new SetSelectedMsisdn(primaryMsisdn));
                } else {
                    this.store.dispatch(new SetSelectedMsisdn(msisdns[0]));
                }
            } else {
                //console.log("CHECK MSISDN STAGE 2");
                let selectedIsInList = false;
                msisdns.forEach((msisdn) => {
                    if (msisdn.msisdn == selectedMsisdn.msisdn) {
                        selectedIsInList = true;
                    }
                });
                if (!selectedIsInList) {
                    this.store.dispatch(new SetSelectedMsisdn(msisdns[0]));
                }
                //IF THE SELECTED MSISDN IS NOT THE PRIMARY WE MUST STILL GET BALANCES FOR THE PRIMARY
                if (!primaryMsisdn?.msisdn) {
                    this.store.dispatch(new GetMsisdnBalance(selectedMsisdn));
                } else if (primaryMsisdn.msisdn != selectedMsisdn.msisdn) {
                    this.store.dispatch(new GetMsisdnBalance(primaryMsisdn));
                    this.store.dispatch(new GetMsisdnBalance(selectedMsisdn));
                } else {
                    this.store.dispatch(new GetMsisdnBalance(primaryMsisdn));
                }
            }
        }
    }
    private setBusinessPartnerHandler() {
        this.store
            .select(BusinessPartnerState.getBusinessPartnerState)
            .subscribe((businessPartner: BusinessPartner | any) => {
                this.storage.set("businessPartnerKey", businessPartner);
            });
    }

    private setSmartShopperCardHandler() {
        this.store
            .select(BusinessPartnerState.getSmartShopperState)
            .subscribe((smartShopperCard: SmartShopperCard | any) => {
                this.storage.set("smartShopperKey", smartShopperCard);
            });
    }

    private setMsisdnsHandler() {
        this.store.select(BusinessPartnerState.getMsisdnsState).subscribe((msisdn: Array<Msisdn>) => {
            this.storage.set("msisdnKey", msisdn);
        });
    }

    private setPrimaryMsisdnsHandler() {
        this.store.select(BusinessPartnerState.getSelectedMsisdnsState).subscribe((primaryMsisdn: Msisdn | any) => {
            this.storage.set("primaryMsisdnKey", primaryMsisdn);
        });
    }

    private setSelectedMsisdnHandler() {
        this.store.select(BusinessPartnerState.getSelectedMsisdnsState).subscribe((selectedMsisdn: Msisdn | any) => {
            this.storage.set("selectedMsisdnKey", selectedMsisdn);
        });
    }

    private setRewardInfoHandler() {
        this.store.select(BusinessPartnerState.getPrimaryMsisdnRewardInfo).subscribe((rewardInfo: any) => {
            this.storage.set("primaryMsisdnRewardInfoKey", rewardInfo);
        });
    }

    private handleError(errorResponse: HttpErrorResponse) {
        //console.log("GOT ERROR");
        //console.log(errorResponse);
        if (errorResponse?.error?.code == 109 || errorResponse?.error?.code == 114) {
            // this.logout();
            //Refrsh the token instead of logging out and if the token is invalid the refresh will logout the user
            this.store.dispatch(new RefreshToken());
        } else {
            let message = "We’re sorry, it looks like there was a technical error. Please try again.";
            let feedback = {
                title: "Error",
                message: message,
                redirect: "",
                btnText: "",
                error: true,
            };
            if (errorResponse?.error?.message) {
                if (
                    errorResponse?.error?.message === "Unexpected error" &&
                    errorResponse?.error?.exceptionTrace
                        .toString()
                        .includes("Business partner already has a linked MVNO accoun")
                ) {
                    message = "Business partner already has a linked MVNO account ";

                    feedback = {
                        title: "Account linked",
                        message: message,
                        redirect: "/home",
                        btnText: "no-btn",
                        error: false,
                    };
                } else {
                    message = errorResponse?.error?.message;
                    feedback = {
                        title: "Error",
                        redirect: "",
                        btnText: "",
                        message: message,
                        error: true,
                    };
                }
            }

            let navigationExtras: NavigationExtras = {
                state: {
                    feedback: feedback,
                },
            };
            this.zone.run(() => {
                this.router.navigate(["feedback"], navigationExtras);
               })

        }
    }

    private handleSuccessResponse(feedback: any) {
        let navigationExtras: NavigationExtras = {
            state: {
                feedback: feedback,
            },
        };

        this.zone.run(() => {
            this.router.navigate(["feedback"], navigationExtras);
        });
    }

    private formatMsisdn(msisdn: any) {
        if (msisdn.charAt(0) == "2" && msisdn.charAt(1) == "7") {
            msisdn = "0" + msisdn.substring(2);
        }
        msisdn = msisdn.substring(0, 10);
        return msisdn.toString().replace(/\D*(\d{3})\D*(\d{3})\D*(\d{4})\D*/, "$1 $2 $3");
    }

    private logout() {
        this.store.dispatch(new Logout()).subscribe(() => {
           this.zone.run(() => {
            this.router.navigate(["/auth/login"]);
           })
        });
    }

    formatDate(date: any) {
        date = new Date(date);
        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var day = date.getDate();
        if (day < 10) {
            day = `${day}`;
        }

        var monthIndex = date.getMonth();
        var year = date.getFullYear();
        return day + " " + monthNames[monthIndex] + " " + year;
    }

    async presentErrorAlert(message: any) {
        const alert = await this.alertController.create({
            cssClass: "my-custom-class",
            header: "Error",
            message: message,
            mode: "ios",
            buttons: ["OK"],
        });

        await alert.present();

        const { role } = await alert.onDidDismiss();
        //console.log("onDidDismiss resolved with role", role);
        //console.log("here");
    }
}
