import { Injectable, NgZone } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { NavController } from "@ionic/angular";
import { NavigationExtras, Router } from "@angular/router";

import { catchError, of, tap } from "rxjs";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";

import { Auth as AuthUtil, TypeChecks } from "src/app/utilities";
import { AuthBasicCredentials, AuthTokenCredentials } from "src/app/domain";
import { AuthService } from "src/app/services/auth.service";
import { AuthStateModel, BasicAuthStateModel, BusinessPartnerModel } from "src/app/models";
import { Config } from "src/app/config";
import {
    ClearSavedCreditCards,
    GetBP,
    GetMsisdns,
    GetSmartShopperBlance,
    ResetBusinessPartner,
} from "../business-partner/business-partner.actions";
import { HideLoader, ShowLoader } from "../loader/loader.actions";
import {
    AddBasicAuth,
    CheckRefreshToken,
    Login,
    LoginBasic,
    LoginBasicWithBasicAuth,
    LoginWithBasicAuth,
    Logout,
    RefreshToken,
    Register,
    SyncAuth,
    UpdateAuthTokensCredentials,
} from "./auth.actions";
import { SecureStorageService } from "src/app/services/secure-storage.service";
import { ToasterMessage } from "src/app/shared/toaster-message";

@State<AuthStateModel>({
    name: "auth",
    defaults: {
        authBasicCredentials: undefined,
        authTokenCredentials: undefined,
        xauthToken: undefined,
    },
})
@State<BusinessPartnerModel>({
    name: "auth",
    defaults: {
        successRegistration: false,
        businessPartner: null,
    },
})
@State<BasicAuthStateModel>({
    name: "auth",
    defaults: {
        username: "",
        password: "",
    },
})
@Injectable()
export class AuthState {
    @Selector()
    static accessToken(state: AuthStateModel): string | null | undefined {
        return state.authTokenCredentials?.accessToken;
    }

    @Selector()
    static isRegistered(state: BusinessPartnerModel): boolean {
        return state.successRegistration;
    }

    @Selector()
    static getAuthTokenCredentials(state: AuthStateModel) {
        return AuthTokenCredentials.fromJson(JSON.stringify(state.authTokenCredentials));
    }

    @Selector()
    static getAuthBasicCredentials(state: AuthStateModel) {
        return AuthBasicCredentials.fromJson(JSON.stringify(state.authBasicCredentials));
    }

    @Selector()
    static loginError(state: AuthStateModel): string | undefined | null {
        return state.authLoginError;
    }

    constructor(
        private authService: AuthService,
        private navCtrl: NavController,
        private router: Router,
        private storage: SecureStorageService,
        private store: Store,
        private toastMsg: ToasterMessage,
        private zone: NgZone
    ) {}

    /**
     * Check if user is authenticated
     * @param state
     */
    @Selector()
    static isAuthenticated(state: AuthStateModel): boolean {
        return state.authTokenCredentials !== undefined && state.authTokenCredentials !== null;
    }

    /**
     * Authenticate user
     *
     * @param getState
     * @param setState
     * @param action
     */
    @Action(Login)
    login({ getState, setState }: StateContext<AuthStateModel>, action: Login) {
        this.store.dispatch(
            new ShowLoader({
                showLoader: true,
                message: "Logging in, please wait...",
            })
        );
        return this.authService.authenticateBusinessPartner(action.payload).pipe(
            tap((response: any) => {
                const accessToken = AuthUtil.decodeJwt(response.access_token);
                const refreshToken = AuthUtil.decodeJwt(response.refresh_token);

                // Get businessPartner
                this.store.dispatch(new GetBP(response.access_token, accessToken.sub)).subscribe(() => {
                    this.store.dispatch(new GetSmartShopperBlance(response.access_token)).subscribe(() => {
                        this.store.dispatch(new HideLoader({ hideLoader: false }));
                        const state = getState();
                        setState({
                            ...state,
                            authBasicCredentials: new AuthBasicCredentials(
                                action.payload.username,
                                action.payload.password
                            ),
                            authTokenCredentials: new AuthTokenCredentials(
                                accessToken.sub,
                                response.access_token,
                                new Date(accessToken.iat * 1000),
                                new Date(accessToken.exp * 1000),
                                response.refresh_token,
                                new Date(refreshToken.exp * 1000)
                            ),
                            authLoginError: null,
                        });

                        this.store.dispatch(new GetMsisdns());
                        this.setAuthBasicCredentialsHandler();
                        this.setAuthTokenCredentialsHandler();

                        /*
                * Todo: enabled firebase when available
                *this.store.dispatch(
                  new LogFirebaseEvent({
                    event: "login_success",
                    data: {},
                  })
                ); */
                    });
                });
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                this.store.dispatch(new HideLoader({ hideLoader: false }));

                /*
        * Todo: enable firebase back whenever available
        * this.store.dispatch(
          new LogFirebaseEvent({
            event: "login_failure",
            data: {
              error_msg: errorResponse?.error?.message
                ? errorResponse?.error?.message
                : errorResponse?.error?.error_description,
              trace_log: errorResponse?.error,
              error_code: errorResponse?.error?.error_code
                ? errorResponse?.error?.error_code
                : errorResponse?.status,
            },
          })
        ); */

                if (typeof errorResponse?.error?.error_code == "undefined" || errorResponse?.error?.error_code == 999) {
                    this.handleError(errorResponse);
                } else if (
                    errorResponse?.error?.error_code == "113" &&
                    errorResponse?.error?.error_description == "Account expired"
                ) {
                    const state = getState();
                    setState({
                        ...state,
                        authLoginError: "Account expired, please contact our support.",
                    });

                    return of();
                } else {
                    const state = getState();
                    setState({
                        ...state,
                        authLoginError: "Incorrect email address or password.",
                    });
                }

                return of();
            })
        );
    }

    /**
     * Basic Login
     * @param getState
     * @param setState
     * @param action
     */
    @Action(LoginBasic)
    loginBasic({ getState, setState }: StateContext<AuthStateModel>, action: LoginBasic) {
        return this.authService.authenticateBusinessPartner(action.payload).pipe(
            tap((response: any) => {
                const accessToken = AuthUtil.decodeJwt(response.access_token);
                const refreshToken = AuthUtil.decodeJwt(response.refresh_token);
                const state = getState();

                setState({
                    ...state,
                    authBasicCredentials: new AuthBasicCredentials(action.payload.username, action.payload.password),
                    authTokenCredentials: new AuthTokenCredentials(
                        accessToken.sub,
                        response.access_token,
                        new Date(accessToken.iat * 1000),
                        new Date(accessToken.exp * 1000),
                        response.refresh_token,
                        new Date(refreshToken.exp * 1000)
                    ),
                    authLoginError: null,
                });
                this.setAuthBasicCredentialsHandler();
                this.setAuthTokenCredentialsHandler();
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                return of();
            })
        );
    }

    /**
     * Register a customer
     * @param param0
     * @param action
     */
    @Action(Register)
    register({ getState, setState }: StateContext<BusinessPartnerModel>, action: Register) {
        this.store.dispatch(
            new ShowLoader({
                showLoader: true,
                message: "creating your account, please wait...",
            })
        );
        return this.authService.registerBusinessPartnerAndUser(action.payload).pipe(
            tap((response: any) => {
                const state = getState();
                setState({
                    ...state,
                    successRegistration: true,
                    businessPartner: response,
                });

                this.store.dispatch(new HideLoader({ hideLoader: false }));
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                this.store.dispatch(new HideLoader({ hideLoader: false }));

                //Go to error page
                let navigationExtras: NavigationExtras = {
                    state: {
                        feedback: {
                            message: "We’re sorry, it looks like there was a technical error. Please try again.",
                            error: true,
                        },
                    },
                };
                this.zone.run(() => this.router.navigate(["feedback"], navigationExtras));
                return of();
            })
        );
    }

    /**
     *
     * @param ctx AddBasicAuth
     * @param action
     */
    @Action(AddBasicAuth)
    addBasicAuth(ctx: StateContext<BasicAuthStateModel>, action: AddBasicAuth) {
        ctx.setState({
            username: action.basicCredentials.username,
            password: action.basicCredentials.password,
        });
        return of();
    }

    @Action(LoginWithBasicAuth)
    loginWithBasicAuth({ getState, setState }: StateContext<BasicAuthStateModel>, action: LoginWithBasicAuth) {
        const basicAuth = getState();
        this.store.dispatch(new Login(basicAuth));
    }

    @Action(LoginBasicWithBasicAuth)
    loginBasicWithBasicAuth(
        { getState, setState }: StateContext<BasicAuthStateModel>,
        action: LoginBasicWithBasicAuth
    ) {
        const basicAuth = getState();
        this.store.dispatch(new LoginBasic(basicAuth));
    }

    @Action(Logout)
    async logout(ctx: StateContext<AuthStateModel>, action: Logout): Promise<void> {
        ctx.patchState({
            authBasicCredentials: new AuthBasicCredentials(undefined, undefined),
            authTokenCredentials: new AuthTokenCredentials(undefined, undefined),
            xauthToken: null,
        });

        const promises: any[] = [];
        Promise.all(promises)
            .then(async () => {
                this.store.dispatch(new ShowLoader({ showLoader: true }));
                this.authService.stopTokenRefreshLoop();

                await this.storage.remove(Config.Auth.authBasicCredentials);
                await this.storage.remove(Config.Auth.authTokenCredentials);
                await this.storage.remove("businessPartnerKey");
                await this.storage.remove("smartShopperKey");
                await this.storage.remove("msisdnKey");
                await this.storage.remove("selectedMsisdnKey");
                await this.storage.remove("primaryMsisdnKey");
                await this.storage.remove("self-rica-id");

                //await this.storage.clear();

                this.store.dispatch(new HideLoader({ hideLoader: false }));
                this.store.dispatch(new ResetBusinessPartner());
                //this.store.dispatch(new ResetPrepaidState()); Todo: update with reset prepaid

                this.store.dispatch(new ClearSavedCreditCards());

                this.store.dispatch(new HideLoader({ hideLoader: false }));
                await this.storage.set("seen-welcome", "true");
                await this.zone.run(async () => {
                    await this.router.navigateByUrl("/auth/login?logout=true");
                });
            })
            .catch(async (onError) => {
                //Clear storage even if it fails
                this.store.dispatch(new HideLoader({ hideLoader: false }));
                await this.storage.clear();
                await this.storage.set("seen-welcome", "true");

                await this.zone.run(async () => {
                    await this.router.navigateByUrl("/auth/login?logout=true");
                });
            });
    }

    @Action(SyncAuth)
    syncAuth({ setState, getState }: StateContext<AuthStateModel>, action: SyncAuth) {
        const state = getState();
        setState({
            ...state,
            authBasicCredentials: action.basicCredentials,
            authTokenCredentials: action.tokenCredentiasl,
        });

        if (action.basicCredentials !== null && action.basicCredentials !== undefined) {
            this.authService.startTokenRefreshLoop();
        }
        //

        return of();
    }

    @Action(CheckRefreshToken)
    // @ts-ignore
    checkRefreshToken({ setState, getState }: StateContext<AuthStateModel>, action: CheckRefreshToken) {
        const state = getState();

        if (state.authTokenCredentials && state.authTokenCredentials.accessTokenExpiryTime) {
            let expireTime = new Date(state.authTokenCredentials.accessTokenExpiryTime).getTime();
            const currentTime = new Date().getTime();
            const hours = Math.abs(expireTime - currentTime) / 36e5;
            let minutes = (expireTime - currentTime) / 60000;
            //console.log('THE MINUTE DIFFERENCE IS ' + minutes);
            //return minutes;
            if (hours < 3) {
                return this.authService.refreshTokenCredentials(state.authTokenCredentials.refreshToken).pipe(
                    tap((response: any) => {
                        const accessToken = AuthUtil.decodeJwt(response.access_token);
                        const refreshToken = AuthUtil.decodeJwt(response.refresh_token);
                        setState({
                            ...state,
                            authBasicCredentials: state.authBasicCredentials,
                            authTokenCredentials: new AuthTokenCredentials(
                                accessToken.sub,
                                response.access_token,
                                new Date(accessToken.iat * 1000),
                                new Date(accessToken.exp * 1000),
                                response.refresh_token,
                                new Date(refreshToken.exp * 1000)
                            ),
                        });

                        this.setAuthBasicCredentialsHandler();
                        this.setAuthTokenCredentialsHandler();
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        this.store.dispatch(new Logout());
                        return of();
                    })
                );
            }
        }
    }

    @Action(RefreshToken)
    refreshToken({ setState, getState }: StateContext<AuthStateModel>, action: RefreshToken) {
        const state = getState();

        if (state.authTokenCredentials !== undefined && state.authTokenCredentials !== null) {
            return this.authService.refreshTokenCredentials(state.authTokenCredentials.refreshToken).pipe(
                tap((response: any) => {
                    const accessToken = AuthUtil.decodeJwt(response.access_token);
                    const refreshToken = AuthUtil.decodeJwt(response.refresh_token);
                    setState({
                        ...state,
                        authBasicCredentials: state.authBasicCredentials,
                        authTokenCredentials: new AuthTokenCredentials(
                            accessToken.sub,
                            response.access_token,
                            new Date(accessToken.iat * 1000),
                            new Date(accessToken.exp * 1000),
                            response.refresh_token,
                            new Date(refreshToken.exp * 1000)
                        ),
                    });

                    this.setAuthBasicCredentialsHandler();
                    this.setAuthTokenCredentialsHandler();
                }),
                catchError((errorResponse: HttpErrorResponse) => {
                    this.store.dispatch(new Logout());
                    return of();
                })
            );
        }

        return;
    }

    @Action(UpdateAuthTokensCredentials)
    updateTokenCredentials({ setState, getState }: StateContext<AuthStateModel>, action: UpdateAuthTokensCredentials) {
        const state = getState();

        setState({
            ...state,
            xauthToken: action.tokenCredentials.access_token,
        });
    }

    /**
     * Set auth basic credentials
     *
     * @private
     */
    private setAuthBasicCredentialsHandler() {
        this.store.select(AuthState.getAuthBasicCredentials).subscribe((credentials: AuthBasicCredentials) => {
            this.storage.set(Config.Auth.authBasicCredentials, JSON.stringify(credentials));
        });

        this.authService.startTokenRefreshLoop();
    }

    /**
     * Set auth token credentials handler
     *
     * @private
     */
    private setAuthTokenCredentialsHandler() {
        this.store.select(AuthState.getAuthTokenCredentials).subscribe((credentials: AuthTokenCredentials) => {
            this.storage.set(Config.Auth.authTokenCredentials, JSON.stringify(credentials));
        });
    }

    /**
     * Handle error from the rest api and dispatch feedback to users
     *
     * @param errorResponse
     * @private
     */
    private handleError(errorResponse: HttpErrorResponse) {
        let message = "We’re sorry, it looks like there was a technical error. Please try again.";
        if (errorResponse?.error?.message) message = errorResponse?.error?.message;

        const feedback = {
            message: message,
            error: true,
        };

        const navigationExtras: NavigationExtras = {
            state: {
                feedback: feedback,
            },
        };
        this.router.navigate(["feedback"], navigationExtras);
    }
}
