import { Syncable } from "./syncable";
import { DeltaSyncable } from "./delta-syncable";
import { TypeChecks } from "src/app/utilities/type-checks";

export class PaymentCardDto extends Syncable {
  bankImageUrl = "";
  cardHolder = "";
  cardImageUrl = "";
  cardNumber = "";
  cardType = "";
  expirationMonth = 0;
  expirationYear = 0;
  id = "";
  token = "";

  constructor(source?: any) {
    super();
    if (source === void 0) return;
    TypeChecks.isString(source.bankImageUrl) &&
      (this.bankImageUrl = source.bankImageUrl);
    TypeChecks.isString(source.cardHolder) &&
      (this.cardHolder = source.cardHolder);
    TypeChecks.isString(source.cardImageUrl) &&
      (this.cardImageUrl = source.cardImageUrl);
    TypeChecks.isString(source.cardNumber) &&
      (this.cardNumber = source.cardNumber);
    TypeChecks.isString(source.cardType) && (this.cardType = source.cardType);
    TypeChecks.isNumberAndNotNaN(source.expirationMonth) &&
      (this.expirationMonth = source.expirationMonth);
    TypeChecks.isNumberAndNotNaN(source.expirationYear) &&
      (this.expirationYear = source.expirationYear);
    TypeChecks.isString(source.id) && (this.id = source.id);
    TypeChecks.isString(source.token) && (this.token = source.token);
  }

  override equals(other: PaymentCardDto): boolean {
    return (
      super.equals(other) &&
      this.bankImageUrl === other.bankImageUrl &&
      this.cardHolder === other.cardHolder &&
      this.cardImageUrl === other.cardImageUrl &&
      this.cardNumber === other.cardNumber &&
      this.cardType === other.cardType &&
      this.expirationMonth === other.expirationMonth &&
      this.expirationYear === other.expirationYear &&
      this.id === other.id &&
      this.token === other.token
    );
  }

  get isValid(): boolean {
    return (
      [this.cardHolder, this.cardNumber, this.cardType].every((prop) => {
        return TypeChecks.isStringAndNotEmpty(prop);
      }) &&
      [this.expirationMonth, this.expirationYear].every((prop) => {
        return TypeChecks.isNumberAndNotNaN(prop);
      })
    );
  }

  get expiryMM() {
    return this.expirationMonth < 10
      ? "0" + this.expirationMonth
      : this.expirationMonth;
  }

  get expiryYY() {
    return this.expirationYear % 100 < 10
      ? "0" + (this.expirationYear % 100)
      : this.expirationYear % 100;
  }

  get isExpired(): boolean {
    const now = new Date();
    // expiry date is the 1st of the next month since the card is technically valid till the end of the month
    const expiryDate = new Date(this.expirationYear, this.expirationMonth, 1);
    return +now > +expiryDate;
  }

  override assign(other: PaymentCardDto, deepAssign = true): PaymentCardDto {
    deepAssign && super.assign(other);
    other.bankImageUrl !== void 0 && (this.bankImageUrl = other.bankImageUrl);
    other.cardHolder !== void 0 && (this.cardHolder = other.cardHolder);
    other.cardImageUrl !== void 0 && (this.cardImageUrl = other.cardImageUrl);
    other.cardNumber !== void 0 && (this.cardNumber = other.cardNumber);
    other.cardType !== void 0 && (this.cardType = other.cardType);
    other.expirationMonth !== void 0 &&
      (this.expirationMonth = other.expirationMonth);
    other.expirationYear !== void 0 &&
      (this.expirationYear = other.expirationYear);
    other.id !== void 0 && (this.id = other.id);
    other.token !== void 0 && (this.token = other.token);
    return this;
  }

  getRestFields() {
    return this.assign.call({}, this, false);
  }

  protected override deconstruct(): object {
    return {
      p: super.deconstruct(),
      i: {
        bankImageUrl: this.bankImageUrl,
        cardHolder: this.cardHolder,
        cardImageUrl: this.cardImageUrl,
        cardNumber: this.cardNumber,
        cardType: this.cardType,
        expirationMonth: this.expirationMonth,
        expirationYear: this.expirationYear,
        id: this.id,
        token: this.token,
      },
    };
  }

  protected override reconstruct(obj: any): PaymentCardDto {
    const { p, i } = obj;
    super.reconstruct(p);
    this.bankImageUrl = i.bankImageUrl;
    this.cardHolder = i.cardHolder;
    this.cardImageUrl = i.cardImageUrl;
    this.cardNumber = i.cardNumber;
    this.cardType = i.cardType;
    this.expirationMonth = i.expirationMonth;
    this.expirationYear = i.expirationYear;
    this.id = i.id;
    this.token = i.token;
    return this;
  }
}

export class PaymentCardsDto extends DeltaSyncable {
  override syncableArray = new Array<PaymentCardDto>(0);

  getSyncable() {
    return new PaymentCardDto();
  }

  override assign(other: PaymentCardsDto): PaymentCardsDto {
    super.assign(other);
    return this;
  }

  protected override deconstruct(): object {
    return super.deconstruct();
  }

  protected override reconstruct(obj: any): PaymentCardsDto {
    super.reconstruct(obj, new PaymentCardDto());
    return this;
  }
}
