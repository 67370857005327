import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Store } from "@ngxs/store";
import { Config } from "../config";
import { QueryAvailabilityModel } from "../models";
import { RefreshToken } from "../state/auth/auth.actions";
import { AuthState } from "../state/auth/auth.state";

const env = Config.Application.ENV;

@Injectable({
    providedIn: "root",
})
export class AuthService {
    public refreshTimeoutId: any | undefined;
    constructor(private http: HttpClient, private store: Store) {}

    /**
     * getHttpHeaders - Http request headers
     *
     * @private
     */
    private getHttpHeaders() {
        return new HttpHeaders({
            accept: "application/json",
            "Content-Type": "application/json",
            "x-pnp-client": "PROFILE",
        });
    }

    /**
     * Basic auth Headers
     * Including client ID and secret id
     */
    private getBasicAuthHeaders() {
        return new HttpHeaders({
            accept: "application/json, text/plain, */*",
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Basic " + btoa(`${env.CLIENT_ID}:${env.SECRET_ID}`),
        });
    }

    /**
     * otpHttpHeaders - Otp headers  required when requesting one time pin
     * @private
     */
    private otpHttpHeaders() {
        return new HttpHeaders({
            accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
        });
    }

    /**
     * cacheBust - Cache busting random string
     *
     * @private
     */
    private cacheBust() {
        return `cacheBust=${Math.random().toString().replace(".", "")}`;
    }

    /**
     * Check if a customer is registered or ot
     * @param payload
     *
     * @return availability_status
     */
    checkRegistrationStatus(payload: QueryAvailabilityModel) {
        const endpoint = `${env.BASE_URI}/registrationstatuses?${payload.query}=${payload.value}`;
        return this.http.get(endpoint, {
            headers: this.getHttpHeaders(),
        });
    }

    /**
     * Add Business partner and User
     * @param payload BusinessPartner object
     */
    registerBusinessPartnerAndUser(payload: any) {
        return this.http.post(`${env.BASE_URI}/businesspartners`, payload, {
            headers: this.getHttpHeaders(),
        });
    }

    /**
     * Login business partner and user
     * @param payload Basic credentials object
     *
     * @param payload
     */
    authenticateBusinessPartner(payload: any) {
        const data = `grant_type=password&username=${payload.username}&password=${payload.password}`;
        return this.http.post(`${env.AUTH_REST_URI}/token`, data, {
            headers: this.getBasicAuthHeaders(),
        });
    }

    /**
     * Refresh token
     * @param refreshToken string
     */
    refreshTokenCredentials(refreshToken: string | undefined) {
        const data = `grant_type=refresh_token&refresh_token=${refreshToken}`;
        return this.http.post(`${env.AUTH_REST_URI}/token?${this.cacheBust()}`, data, {
            headers: this.getBasicAuthHeaders(),
        });
    }

    /**
     * Stop refresh token
     */
    stopTokenRefreshLoop() {
        if (this.refreshTimeoutId) {
            clearTimeout(this.refreshTimeoutId);

            this.refreshTimeoutId = null;
        }
    }

    /**
     * Refresh token loop
     */
    public startTokenRefreshLoop() {
        this.store.select(AuthState.getAuthTokenCredentials).subscribe((auth) => {
            if (auth !== undefined) {
            }
            if (auth !== null && auth.accessTokenIssueTime && auth.accessTokenExpiryTime) {
                this.stopTokenRefreshLoop();
                // this.refreshTimeoutId = 0;
                const now = new Date();
                const { accessTokenIssueTime, accessTokenExpiryTime } = auth;

                const nextAccessTokenRefreshTime = new Date(
                    Math.floor((accessTokenIssueTime.getTime() + accessTokenExpiryTime.getTime()) / 2)
                );

                const nextAccessTokenRefreshOffsetMs = nextAccessTokenRefreshTime.getTime() - now.getTime();

                if (!Number.isNaN(nextAccessTokenRefreshOffsetMs) && nextAccessTokenRefreshOffsetMs > 1)
                    this.refreshTimeoutId = setTimeout(
                        async () => await this.store.dispatch(new RefreshToken()),
                        nextAccessTokenRefreshOffsetMs //10000 Demo 10 seconds
                    );
                else return;
            }
        });
    }

    /**
     * Request otp
     * @param payload
     * @returns
     */
    requestOtp(payload: any) {
        return this.http.post(`${env.AUTH_REST_URI}/auth/onetimepasswordrequests`, payload, {
            headers: this.otpHttpHeaders(),
        });
    }

    /**
     * Validate otp
     * @param payload
     * @returns
     */
    validateOtp(payload: any) {
        const data = `grant_type=urn:picknpay:params:oauth:grant-type:otp&one_time_password=${payload.otp}&principal_type=MVNO_ACCOUNT_NUMBER&principal=${payload.mvnoAccount}`;
        return this.http.post(`${env.AUTH_REST_URI}/token`, data, {
            headers: this.getBasicAuthHeaders(),
        });
    }
}
