export class Auth {
  private static b64DecodeUnicode(str: string) {
    return decodeURIComponent(
      atob(str).replace(/(.)/g, function (m, p) {
        let code = p.charCodeAt(0).toString(16).toUpperCase();
        if (code.length < 2) {
          code = "0" + code;
        }
        return "%" + code;
      })
    );
  }

  private static base64UrlDecode(str: string) {
    let output = str.replace(/-/g, "+").replace(/_/g, "/");
    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += "==";
        break;
      case 3:
        output += "=";
        break;
      default:
        throw "Illegal base64url string!";
    }
    try {
      return Auth.b64DecodeUnicode(output);
    } catch (err) {
      return atob(output);
    }
  }

  static decodeJwt(token: any) {
    return JSON.parse(Auth.base64UrlDecode(token.toString().split(".")[1]));
  }
}
