import { Injectable } from "@angular/core";
import { IonRefresher, LoadingController } from "@ionic/angular";
import { Subscription } from "rxjs";

@Injectable()
export class Loader {
  isLoading: any = false;
  refresher: IonRefresher | undefined;

  constructor(public loadingController: LoadingController) {}

  /**
   * @param message (the loading message)
   * @param additionalOption  (any addition option to pass)
   */
  async showLoading(
    message: string = "Please wait...",
    additionalOption?: any
  ) {
    const loader = await this.loadingController.create({
      message,
      spinner: "circles",
      duration: 15000, //Good to have a max time to avoid infinite loading
    });

    return await loader.present();
  }
  /**
   * Dismiss the loader
   */
  async dismissLoader() {
    try {
      return await this.loadingController.dismiss();
    } catch (err) {
      return true;
    }
  }
}
