import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";
import { ToastOptionstModel } from "src/app/models/toastOptionsModel";

@Injectable()
export class ToasterMessage {
  constructor(public toastCtrl: ToastController) {}

  async showToaster(toastOptions: ToastOptionstModel) {
    const toaster = await this.toastCtrl.create({
      message: toastOptions.message,
      position: toastOptions.elementPosition
        ? toastOptions.elementPosition
        : "bottom",
      duration: toastOptions.duration ? toastOptions.duration : 5000,
      cssClass: toastOptions.cssClass ? toastOptions.cssClass : "",
    });
    await toaster.present();
  }
}
