import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment as env } from "src/environments/environment";
import { Store } from "@ngxs/store";
import {
  AuthStateModel,
  BusinessPartner,
  BusinessPartnerModel,
  ChangePasswordModel,
  ConvertAirtimeModel,
  PrepaidOrderModel,
} from "src/app/models";
import { PrepaidBundleDto as PrepaidBundle } from "../domain/syncable/prepaid-bundles";

@Injectable({
  providedIn: "root",
})
export class PnPHttpService {
  /**
   * There's a possibility to have two base endpoints
   * one for authentication and second one for data
   */
  baseUrl: any = "";
  authRest: any = "";

  constructor(private http: HttpClient, private store: Store) {
    this.baseUrl = env.BASE_URI;
    this.authRest = env.AUTH_REST_URI;
  }

  /**
   * Auth Headers including token
   */
  private authHeadersWithToken() {
    //const token = this.store.selectSnapshot((state: AuthStateModel) => state)['auth']['authTokenCredentials']['accessToken'];
    return new HttpHeaders({
      accept: "*/*",
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,//I have added token from interceptor, please have a look on interceptor.
    });
  }

  /**
   * Headers with x authorization
   * @returns headers object
   */
  private authHeadersWithTokenWithXAuth() {
    // @ts-ignore
    const token = this.store.selectSnapshot((state: AuthStateModel) => state)[
      "auth"
      ];
    return new HttpHeaders({
      accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token["authTokenCredentials"]["accessToken"]}`,
      "x-authorization-target": `Bearer ${token["xauthToken"]}`,
      "x-pnp-client": "PROFILE"
    });
  }

  /**
   * Auth Headers manual add token
   */
  private authHeadersManualToken(token: string) {
    return new HttpHeaders({
      accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
  }

  /**
   * Get businessPartner base details
   * @param payload BusinessPartner object
   */
  getBusinessPartner(token = null, guid = null) {
    if (token == null || guid == null) {
      // @ts-ignore
      let guid = this.store.selectSnapshot((state: AuthStateModel) => state)[
        "auth"
        ]["authTokenCredentials"]["guid"];
      return this.http.get(`${env.BASE_URI}/businesspartners?guid=${guid}`, {
        headers: this.authHeadersWithToken(),
      });
    } else {
      return this.http.get(`${env.BASE_URI}/businesspartners?guid=${guid}`, {
        headers: this.authHeadersManualToken(token),
      });
    }
  }

  /**
   * Add SS card details
   * @param token
   */
  getSmartShopperAccount(token: string | null | undefined) {
    // @ts-ignore
    const smartshopper = this.store.selectSnapshot(
      (state: BusinessPartnerModel) => state
    )["businessPartner"]["businessPartnerMain"]["smartShopperAccountNumber"];

    const endpoint = `${env.BASE_URI}/smartshopperaccounts/${smartshopper}`;

    if (token == null || undefined)
      return this.http.get(
        endpoint,
        {
          headers: this.authHeadersWithToken(),
        }
      );
      else return this.http.get(
      endpoint,
      {
        headers: this.authHeadersManualToken(token),
      }
    );
  }

  /**
   * Get user's msisdns
   * @param payload Msisnds object
   */
  getMsisdns(businessPartnerId: any, mvnoAccount: any) {
    return this.http.get(
      `${env.BASE_URI}/businesspartners/${businessPartnerId}/mvno/accounts/${mvnoAccount}/msisdns`,
      {
        headers: this.authHeadersWithToken(),
      }
    );
  }

  /**
   * RESET PASSWORd
   * @param payload
   */
  resetPassword(payload: any) {
    return this.http.post(`${env.RESET_PASSWORD_URI}`, payload, {
      headers: new HttpHeaders({
        accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      }),
    });
  }

  /**
   * Get linkoptions -> msisdns
   * @param id_passport
   * @param value
   */
  accountLinkOptions(id_passport: any, value: any) {
    const data = `${id_passport}=${value}`;
    return this.http.get(`${env.BASE_URI}/mvno/accountlinkoptions?${data}`, {
      headers: this.authHeadersWithToken(),
    });
  }

  /**
   * Get msisnd balances
   * @param payload bpID, mvno account, msisdn
   */
  getMsisdnBalance(payload: any) {
    //suggested payload:MsisdnBalanceModel
    return this.http.get(
      `${env.BASE_URI}/businesspartners/${payload.bpid}/mvno/accounts/${payload.mvnoAccountNumber}/msisdns/${payload.msisdn}/balances`,
      {
        headers: this.authHeadersWithToken(),
      }
    );
  }

  /*
   * Get link mvno account to businesspartner
   * @param payload id_passport and value
   */
  accountLinkToBp(payload: any) {
    let bpID = payload.businessPartnerId;
    delete payload.businessPartnerId;
    let finalPayload = {
      identificationType: payload.identificationType,
      identificationNumber: payload.identificationNumber,
      mvnoAccountNumber: payload.mvnoAccountNumber,
    };
    return this.http.post(
      `${env.BASE_URI}/businesspartners/${bpID}/mvno/accountmanagementrequests`,
      finalPayload,
      {
        headers: this.authHeadersWithTokenWithXAuth(),
      }
    );
  }

  /*
   * Get link mvno account for REWARDS
   */
  accountLinkPrimary(payload: any) {
    return this.http.post(
      `${env.BASE_URI}/businesspartners/${payload.businessPartnerId}/mvno/accounts/${payload.mvnoAccountNumber}/msisdns/${payload.msisdn}/linkrewards `,
      null,
      {
        headers: this.authHeadersWithToken(),
      }
    );
  }

  /**
   * Transfer reawrads to secondary msisdn
   * @param payload amount to transfer
   */
  transferRewards(value: any) {
    const data = `emailAddressOrUsername=${value}`;
    return this.http.post(`${env.BASE_URI}/test`, data, {
      headers: this.authHeadersWithToken(),
    });
  }

  /**
   * Transfer data to any msisnmd
   * @param payload amount to transfer
   */
  transferData(payload: any) {
    let recipient = payload.recipient.toString();

    //Convert to SA number
    if (recipient.substring(0, 1) === "0") {
      recipient = "27" + recipient.substring(1);
    } else if (recipient.substring(0, 2) !== "27") {
      recipient = "27" + recipient;
    }

    let finalPayload = {
      balanceId: payload.balanceId,
      recipient: recipient,
      unit: payload.unit,
      amount: payload.amount,
    };

    return this.http.post(
      `${env.BASE_URI}/businesspartners/${payload.BPId}/mvno/accounts/${payload.mvnoAccountNumber}/msisdns/${payload.from}/balancetransferrequests`,
      finalPayload,
      {
        headers: this.authHeadersWithToken(),
      }
    );
  }

  /**
   * Update the Alias of any msisdn
   * @param payload msisdn to update and latest alias
   */
  updateMsisdnAlias(payload: any) {
    let finalPayload = {
      alias: payload.alias,
    };

    return this.http.post(
      `${env.BASE_URI}/businesspartners/${payload.BPId}/mvno/accounts/${payload.mvnoAccountNumber}/msisdns/${payload.msisdn}/msisdnaliasupdates`,
      finalPayload,
      {
        headers: this.authHeadersWithToken(),
      }
    );
  }

  /**
   * Get reward tier info balances
   * @param payload bpID, mvno account, msisdn
   */
  getRewardInfo(payload: any) {
    //suggested payload:MsisdnBalanceModel
    return this.http.get(
      `${env.BASE_URI}/businesspartners/${payload.bpid}/mvno/accounts/${payload.mvnoAccountNumber}/msisdns/${payload.msisdn}/rewardinfo`,
      {
        headers: this.authHeadersWithToken(),
      }
    );
  }

  /**
   * Get MSISDNs by ID/Passport
   */

  getContactsForOtpById() {
    //Todo: get endpoints from CMS
    //Make a request and get contact numbers to send otp
    //Make sure contacts are masked

    return this.http.get("https://www.google.com");
  }

  /**
   * Get Bundles Airtime and Data
   * Variable Airtime and Data
   * @param type
   */
  fetchesBundlesCatalogue(businessPartnerId: any) {
    return this.http.get(
      `${env.BASE_URI}/businesspartners/${businessPartnerId}/mvno/products`,
      {
        headers: this.authHeadersWithToken(),
      }
    );
  }

  /**
   * Convert Airtime to Data bundles
   * @param payload
   * @returns
   */
  convertAirtime(payload: ConvertAirtimeModel) {
    const convertAirtimePayload = {
      // amount: payload?.amount,
      productId: payload?.productId,
      productType: payload?.productType,
    };

    return this.http.post(
      `${env.BASE_URI}/businesspartners/${payload.businessPartnerId}/mvno/accounts/${payload.mvnoAccountNumber}/msisdns/${payload.msisdn?.msisdn}/convertairtime`,
      convertAirtimePayload,
      {
        headers: this.authHeadersWithToken(),
      }
    );
  }

  /**
   * Fetch saved payments credit cards
   * @param BusinessPartner ID
   * @returns
   */
  fetchSavedBankCards(businessPartnerId: any) {
    return this.http.get(
      `${env.BASE_URI}/businesspartners/${businessPartnerId}/paymentcards`,
      {
        headers: this.authHeadersWithToken(),
      }
    );
  }

  /**
   * Change user password
   * @param payload
   * @returns
   */
  changePassword(payload: ChangePasswordModel) {
    const payloadData = {
      oldPassword: payload?.oldPassword,
      newPassword: payload?.newPassword,
    };

    return this.http.post(
      `${env.BASE_URI}/users/${payload.username}/passwordchangerequests`,
      payloadData,
      {
        headers: this.authHeadersWithToken(),
      }
    );
  }

  /**
   * Create bundle payment order
   */
  createBundlePaymentOrder(payload: PrepaidOrderModel, bp: BusinessPartner) {
    let endpoint = `${env.BASE_URI}/businesspartners/${bp.id}/mvno/accounts/${bp.mvnoManagedAccounts[0]}/msisdns/${payload.primaryOrSelectedMsisdn.msisdn}/createorders`;

    const dataPayload = {
      orderRequest: payload.orderRequest,
      paymentMethods: payload.paymentMethods,
    };

    return this.http.post(endpoint, dataPayload, {
      headers: this.authHeadersWithToken(),
    });
  }

  /**
   * Create bundle payment order
   */
  fetchOrderStatus(orderRef: String, bp: BusinessPartner) {
    return this.http.get(
      `${env.BASE_URI}/businesspartners/${bp.id}/mvno/accounts/${bp.mvnoManagedAccounts[0]}/orders?orderRef=${orderRef}`,
      {
        headers: this.authHeadersWithToken(),
      }
    );
  }

  /**
   * Fetch purchase history
   * @param bp object
   * @returns
   */
  fetchPurchaseHistory(bp: BusinessPartner) {
    return this.http.get(
      `${env.BASE_URI}/businesspartners/${bp.id}/mvno/accounts/${bp.mvnoManagedAccounts[0]}/orderHistory`,
      {
        headers: this.authHeadersWithToken(),
      }
    );
  }

  purchaseAirtimeOrData(payload: PrepaidBundle) { }
}
