import { PrepaidBundleDto as PrepaidBundle } from "src/app/domain/syncable/prepaid-bundles";
import {
  BusinessPartner,
  ConvertAirtimeModel,
  PrepaidOrderModel,
  SelectedPaymentMethodsModel,
} from "src/app/models";

export class LoadAirtimeAndDataBundles {
  static readonly type = "[PrepaidBundles] LoadAirtimeAndDataBundles";

  constructor(
    public payload: {
      businessPartnerId: any;
    }
  ) {}
}

export class BuyAirtimeBundles {
  static readonly type = "[PrepaidBundles] BuyAirtimeBundles";
  //Todo: review what's needed between phone number amount
  constructor(
    public payload: {
      msisdn: Number;
      bundleValue: Number;
      bundleAmount: Number;
    }
  ) {}
}

export class BuyDataBundles {
  static readonly type = "[PrepaidBundles] BuyDataBundles";
  //Todo: review what's needed between phone number amount
  constructor(
    public payload: {
      msisdn: Number;
      bundleValue: Number;
      bundleAmount: Number;
    }
  ) {}
}

export class ConvertAirtimeBundles {
  static readonly type = "[PrepaidBundles] ConvertAirtimeBundles";

  constructor(public payload: ConvertAirtimeModel) {}
}

export class SetSelectedBundle {
  static readonly type = "[PrepaidBundles] SetSelectedBundle";

  constructor(public payload: { bundle?: PrepaidBundle }) {}
}

export class SetSelectedPaymentMethod {
  static readonly type = "[PrepaidBundles] SetSelectedPaymentMethod";

  constructor(public selectedMethods: SelectedPaymentMethodsModel) {}
}
export class CreatePrepaidOrder {
  static readonly type = "[PrepaidBundles] CreatePrepaidOrder";

  constructor(
    public payload: {
      order: PrepaidOrderModel;
      bp: BusinessPartner;
    }
  ) {}
}

export class QueryPrepaidOrderStatus {
  static readonly type = "[PrepaidBundles] QueryPrepaidOrderStatus";

  constructor(
    public payload: {
      orderRef: String;
      bp: BusinessPartner;
    }
  ) {}
}

export class DestroyPrepaidOrder {
  static readonly type = "[PrepaidBundles] ResetPrepaidState";
}
export class ResetPrepaidState {
  static readonly type = "[PrepaidBundles] ResetPrepaidState";
}

export class AbortOrder {
  static readonly type = "[PrepaidBundles] AbortOrder";
}

export class CancelOrder {
  static readonly type = "[PrepaidBundles] CancelOrder";
}
