import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/pages.routes').then((m) => m.pagesRoutes)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.routes').then((m) => m.routes),
  },
  {
    path: 'prepaid-feedback',
    loadComponent: () => import('./shared/prepaid-feedback/prepaid-feedback.page').then( m => m.PrepaidFeedbackPage)
  },
  {
    path: 'client-feedback',
    loadComponent: () => import('./shared/client-feedback/client-feedback.page').then( m => m.ClientFeedbackPage)
  },
  {
    path: 'browser',
    loadComponent: () => import('./shared/browser/browser.page').then( m => m.BrowserPage)
  },
  {
    path: 'payments',
    loadComponent: () => import('./shared/payments/payments.page').then( m => m.PaymentsPage)
  },
  {
    path: 'payu',
    loadComponent: () => import('./shared/payments/payu/payu.page').then( m => m.PayuPage)
  },
];
