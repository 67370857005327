import { Syncable } from "./syncable";
import { Crc } from "src/app/utilities";

export abstract class DeltaSyncable extends Syncable {
  syncableArray: Syncable[] = [];

  constructor() {
    super();
  }

  abstract getSyncable(): Syncable;

  get isValid(): boolean {
    if (!this.syncableArray || this.syncableArray.length === 0) {
      return false;
    }
    if (this.syncableArray.every((syncable) => syncable.isValid)) {
      return true;
    }
    const newArray = new Array<Syncable>(0);
    this.syncableArray
      .filter((syncable) => syncable.isValid)
      .forEach((syncable) => newArray.push(syncable));
    this.syncableArray = newArray;
    return this.syncableArray.length > 0;
  }

  override equals(other: DeltaSyncable): boolean {
    const [arr1, arr2] = [this.syncableArray, other.syncableArray];
    if ((arr1 && !arr2) || (!arr1 && arr2)) {
      return false;
    }
    if (!arr1 && !arr2) {
      return true;
    }
    if (arr1.length !== arr2.length) {
      return false;
    }
    for (let i = 0, length = arr1.length; i < length; ++i) {
      if (!arr1[i].equals(arr2[i])) {
        return false;
      }
    }
    return true;
  }

  override clone() {
    const copy = new (<any>this.constructor)();
    return copy.assign(this);
  }

  override assign(other: DeltaSyncable): DeltaSyncable {
    super.assign(other);
    if (!other.syncableArray) {
      this.syncableArray = other.syncableArray;
      return this;
    }
    this.syncableArray = new Array<Syncable>(other.syncableArray.length);
    other.syncableArray.forEach((syncable, index) => {
      this.syncableArray[index] = syncable.clone();
    });
    return this;
  }

  assignFromObjectArray(syncable: Syncable, objArray: any[]) {
    this.syncableArray = new Array<Syncable>(0);
    for (let i = 0; i < objArray.length; ++i) {
      const syncableObj = <Syncable>new (<any>syncable.constructor)();
      syncableObj.assignFromObject(objArray[i]);
      this.syncableArray.push(syncableObj);
    }
    return this;
  }

  protected override deconstruct(): object {
    // @ts-ignore
    let i: Array<object> = null;
    if (!this.syncableArray) {
      i = this.syncableArray;
    } else {
      i = new Array<object>(this.syncableArray.length);
      for (let j = 0, length = this.syncableArray.length; j < length; ++j) {
        i[j] = (<any>this.syncableArray[j]).deconstruct();
      }
    }
    return {
      p: super.deconstruct(),
      i,
    };
  }

  protected override reconstruct(obj: any, syncable?: Syncable): DeltaSyncable {
    const { p, i } = obj;
    super.reconstruct(p);
    if (!i) {
      this.syncableArray = i;
    } else {
      this.syncableArray = new Array<Syncable>(i.length);
      const syncableConstructor =
        (syncable && syncable.constructor) || this.constructor;
      for (let j = 0, length = i.length; j < length; ++j) {
        this.syncableArray[j] = new (<any>syncableConstructor)().reconstruct(
          i[j]
        );
      }
    }
    this.coerceTypes();
    return this;
  }

  override get crc(): number {
    let crc = super.getCrc();
    if (!this.syncableArray || this.syncableArray.length === 0) {
      return crc;
    }
    this.syncableArray.forEach((syncable) => {
      crc = Crc.crc32String(syncable.serialise(), crc);
    });
    return crc;
  }

  get crcArray(): Array<number> {
    if (!this.syncableArray || this.syncableArray.length === 0) {
      return [];
    }
    const crcs = new Array<number>(this.syncableArray.length);
    this.syncableArray.forEach((syncable, index) => {
      crcs[index] = syncable.crc;
    });
    return crcs;
  }
}
