import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment as env } from "src/environments/environment";
import { Store } from "@ngxs/store";
import { SecureStorageService as Storage } from "src/app/services/secure-storage.service";

@Injectable({
    providedIn: "root",
})
export class CmsApiService {
    /**
     * Base URL for the CMS
     */
    baseUrl: any = "";

    constructor(private http: HttpClient, private store: Store, private storage: Storage) {
        this.baseUrl = env.production ? env.S3_PROD_CDN : env.S3_DEV_QA_CDN; //env.CMS_API_URL;
    }

    /**
     * Auth Headers including token
     */
    private authHeadersWithToken() {
        return new HttpHeaders({
            accept: "*/*",
            "Content-Type": "application/json",
        });
    }

    /**
     * Auth excluding  token
     */
    private authHeaders() {
        return new HttpHeaders({
            accept: "*/*",
            "Content-Type": "application/json",
        });
    }

    /**
     * Get the token from storage
     */
    async getToken() {
        return await this.storage.get("CMS_API_TOKEN");
    }

    getTransferAmountsRewardData() {
        return this.http.get(`${this.baseUrl}/transsfer-rewarddata.json`);
    }

    getRewardInfo() {
        return this.http.get(`${this.baseUrl}/reward-info.json`);
    }

    getPromoInfo() {
        return this.http.get(`${this.baseUrl}/promo.json`);
    }

    getTransferAmountsData() {
        return this.http.get(`${this.baseUrl}/transfer-data.json`);
    }

    getAirtimeConvertAmounts() {
        return this.http.get(`${this.baseUrl}/convert.json`);
    }

    getProducts() {
        return this.http.get(`${this.baseUrl}/products.json`);
    }
}
