import { State } from "src/app/state/state";
import { Crc } from "src/app/utilities";

export abstract class Syncable extends State {
  abstract readonly isValid: boolean;
  isUpdated: boolean | undefined;
  isPristine: boolean | undefined;

  constructor() {
    super();
  }

  equals(other: Syncable): boolean {
    return (
      this.isValid === other.isValid &&
      this.isUpdated === other.isUpdated &&
      this.isPristine === other.isPristine
    );
  }

  clone() {
    const copy = new (<any>this.constructor)();
    return copy.assign(this);
  }

  assign(other: Syncable): Syncable {
    this.isUpdated = other.isUpdated;
    this.isPristine = other.isPristine;
    return this;
  }

  assignFromObject(obj: object): Syncable {
    Object.assign(this, obj);
    if (!this.isValid) {
      this.coerceTypes();
    }
    return this;
  }

  coerceTypes() {}

  serialise(): string {
    return JSON.stringify(this.deconstruct());
  }

  deserialise(json: string): Syncable {
    this.reconstruct(JSON.parse(json));
    this.coerceTypes();
    return this;
  }

  protected deconstruct(): object {
    // return {
    //     i: {
    //         isUpdated: this.isUpdated,
    //         isPristine: this.isPristine
    //     }
    // };

    return {};
  }

  protected reconstruct(obj: any): Syncable {
    // const { i } = obj;
    // this.isUpdated = i.isUpdated;
    // this.isPristine = i.isPristine;
    // return this;

    return this;
  }

  // must be method; derived types can't use super.property, only super.method()
  protected getCrc(): number {
    return Crc.crc32String(this.serialise());
  }

  get crc(): number {
    return Crc.crc32String(this.serialise());
  }
}
