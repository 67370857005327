import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { NavController } from "@ionic/angular";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";
import { AuthState } from "src/app/state/auth/auth.state";
import { map, tap } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class AuthGuard implements CanActivate {
    isLoggedIn!: boolean;
    @Select(AuthState.isAuthenticated) authenticated$?: Observable<boolean>;

    constructor(private navCtrl: NavController) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.authenticated$!.pipe(
            map((status) => {
                if (typeof status !== undefined && status !== null) {
                    this.isLoggedIn = status;
                    return status;
                }
                return false;
            }),
            tap((isLoggedIn) => {
                if (!isLoggedIn) {
                    this.navCtrl.navigateRoot("/auth/login");
                }
            })
        );
    }
}
