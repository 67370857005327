export class Msisdn {
  msisdn?: string;

  alias?: string;

  isPrimary?: boolean;

  accountNumber?: string;

  displayMsisdn?: string;

  balances?: any;

  ricaDateTime?: any;

  ricaDateTimeDisplay?: any;
}
export namespace Msisdn { }
