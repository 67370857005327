import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Crypto, Crc } from 'src/app/utilities';
import { Config } from 'src/app//config';

import Encrypt = Config.Storage.Encrypt;


const {
  VersionKey: StorageVersionKey,
  Version: StorageVersion,
} = Config.Storage;

@Injectable({
  providedIn: "root",
})
export class SecureStorageService {
  constructor(
    private storage: Storage
  ) {
  }


  async get(key: any): Promise<any> {
    Encrypt && (key = Crypto.encrypt(key, Crc.crc32String(key)));
    const value = await this.storage.get(key);
    if (!value)
      return value;
    return Encrypt ? JSON.parse(Crypto.decrypt(value)) : JSON.parse(value);
  }

  set(key: any, value: any): Promise<any> {
    value = JSON.stringify(value);
    if (Encrypt) {
      key = Crypto.encrypt(key, Crc.crc32String(key));
      value = Crypto.encrypt(value);
    }
    return this.storage.set(key, value);
  }

  remove(key: string): Promise<any> {
    Encrypt && (key = Crypto.encrypt(key, Crc.crc32String(key)));
    return this.storage.remove(key);
  }

  encryptKey(key: string): any {
    return Crypto.encrypt(key, Crc.crc32String(key));
  }

  decryptKey(key: string): any {
    return Crypto.decrypt(key);
  }

  clear(): Promise<{}> {
    return new Promise((resolve: any, reject) => {
      this.storage.clear().then(resolve).catch(reject);
    });
  }
}
