import { Injectable, NgZone } from "@angular/core";
import { State, Action, StateContext, Selector } from "@ngxs/store";
import { HideLoader, ShowLoader, ShowUserFeedback } from "./loader.actions";
import { Loader } from "src/app/shared";
import { of } from "rxjs";
import { NavigationExtras, Router } from "@angular/router";

export interface LoaderModel {
  isLoading: boolean;
}

@State<LoaderModel>({
  name: "loader",
  defaults: {
    isLoading: false,
  },
})
@Injectable()
export class LoaderState {
  @Selector()
  static isLoading(state: LoaderModel): boolean {
    return state.isLoading;
  }

  constructor(
    public globalLoader: Loader,
    private router: Router,
    private zone: NgZone
  ) {}

  @Action(ShowLoader)
  async showLoading(ctx: StateContext<LoaderModel>, action: ShowLoader) {
    return await this.globalLoader
      .showLoading(action.payload.message)
      .then(() => {
        ctx.patchState({
          isLoading: action.payload.showLoader,
        });
      });
  }

  @Action(HideLoader)
  async dismissLoading(ctx: StateContext<LoaderModel>, action: HideLoader) {
    return await this.globalLoader.dismissLoader().then(() => {
      ctx.patchState({
        isLoading: false,
      });
    });
  }

  @Action(ShowUserFeedback)
  handleSuccessOrErrorMessage(
    ctx: StateContext<LoaderModel>,
    action: ShowUserFeedback
  ) {
    this.goNext(action.feedback);
  }

  goNext(feedback: any) {
    this.zone.run(() => {
      let navigationExtras: NavigationExtras = {
        state: {
          feedback: feedback,
        },
      };
      this.router.navigate(["feedback"], navigationExtras);
    });
  }
}
