import { environment } from "src/environments/environment";

export namespace Config {
    export class Application {
        static readonly ENV = {
            APP_VERSION: "1.0.81",
            AUTH_REST_URI: environment.AUTH_REST_URI,
            BASE_URI: environment.BASE_URI,
            CLIENT_ID: environment.CLIENT_ID,
            RESET_PASSWORD_URI: environment.RESET_PASSWORD_URI,
            S3_DEV_QA_CDN: environment.S3_DEV_QA_CDN,
            S3_PROD_CDN: environment.S3_PROD_CDN,
            SECRET_ID: environment.SECRET_ID,
            PAYU_RETURN_URI: environment.PAYU_RETURN_URI,
            PAYU_CANCEL_URI: environment.PAYU_CANCEL_URI,
        };
    }

    export namespace Storage {
        export const VersionKey = "VersionKey";
        export const Version = Application.ENV.APP_VERSION;
        export const Encrypt = true;
    }

    export namespace Auth {
        export const authBasicCredentials = "authBasicCredentials";
        export const authTokenCredentials = "authTokenCredentials";
    }

    export namespace BusinessPartner {
        export const businessPartner = "businessPartner";
    }
}
