import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sim-not-linked',
  templateUrl: './sim-not-linked.component.html',
  styleUrls: ['./sim-not-linked.component.scss'],
})
export class SimNotLinkedComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
