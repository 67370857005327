import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { SyncService } from "./sync.service";
import { AuthGuard } from "./guard/auth.guard";
import { HideLoader } from "src/app/state/loader/loader.actions";
import { Store } from "@ngxs/store";

@Injectable({
    providedIn: "root",
})
export class NetworkService {
    constructor(
        private router: Router,
        private zone: NgZone,
        private location: Location,
        private sync: SyncService,
        private auth: AuthGuard,
        private store: Store
    ) {}

    async checkNetworkStatus() {
        const status = navigator.onLine;
        if (!status)
            await this.zone.run(async () => {
                await this.router.navigateByUrl("offline");
            });

        return status;
    }

    getNetworkStatus = () => navigator.onLine;

    async checkBackOnline() {
        if (navigator.onLine) {
            if (this.auth.isLoggedIn) await this.sync.foregroundSync();
            this.location.back();
        }
    }

    hideLoader = () =>
        setTimeout(async () => {
            await this.store.dispatch(new HideLoader({ hideLoader: false }));
        }, 2000);
}
