import { enableProdMode, importProvidersFrom, isDevMode } from "@angular/core";
import { bootstrapApplication } from "@angular/platform-browser";
import { RouteReuseStrategy, provideRouter } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";

import { Drivers } from "@ionic/storage";
import { routes } from "./app/app.routes";
import { MVNOAppComponent } from "./app/app.component";
import { environment } from "./environments/environment";

import { NgxsModule } from "@ngxs/store";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { IonicStorageModule } from "@ionic/storage-angular";
import { SecureStorageService } from "./app/services/secure-storage.service";
import { SharedModule } from "@/app/shared/shared.module";
import { AuthState } from "@/app/state/auth/auth.state";
import { LoaderState } from "@/app/state/loader/loader.state";
import { BusinessPartnerState } from "@/app/state/business-partner/business-partner.state";
import { PrepaidBundlesStates } from "@/app/state/prepaid-bundles/prepaid-bundles.state";
import { Loader, ToasterMessage } from "@/app/shared";

// Plugins
import { NetworkService } from "@/app/services/network.service";
import { SyncService } from "@/app/services/sync.service";
import { TokenInterceptor } from "@/app/interceptors/token.interceptor";
import { HTTP_INTERCEPTORS } from "@angular/common/http";

// import dev modules here
let devModules = [NgxsLoggerPluginModule.forRoot(), NgxsReduxDevtoolsPluginModule.forRoot()];

if (environment.production) {
    devModules = [];
    enableProdMode();
}

function register() {
    // register service worker
    if (navigator.serviceWorker) {
        navigator.serviceWorker
            .register("./service-worker.js")
            .then((registration) => {
            })
            .catch(console.log);
    }
}

// use PWA functionality only in production to prevent asset caching during development
if (environment.production) {
    register();
} else {
    // don't use PWA during development
    console.log(`environment.production: ${environment.production}; not using PWA functionality.`);
    // debug-pwa
    register();
}

bootstrapApplication(MVNOAppComponent, {
    providers: [
        ToasterMessage,
        Loader,
        NetworkService,
        SyncService,
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        importProvidersFrom(
            IonicModule.forRoot({}),
            SecureStorageService,
            SharedModule,
            IonicStorageModule.forRoot({
                driverOrder: [Drivers.IndexedDB],
            }),
            NgxsModule.forRoot([AuthState, LoaderState, BusinessPartnerState, PrepaidBundlesStates], {
                developmentMode: !environment.production,
            }),
            ...devModules
        ),
        provideRouter(routes),
    ],
});
