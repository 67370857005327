import { AuthBasicCredentials, AuthTokenCredentials } from "src/app/domain";
import {
  BusinessPartner,
  QueryAvailabilityModel,
  XAuthTokens,
} from "src/app/models";

export class Login {
  static readonly type = "[Auth] Login";
  constructor(public payload: { username: string; password: string }) {}
}

export class LoginBasic {
  static readonly type = "[Auth] LoginBasic";
  constructor(public payload: { username: string; password: string }) {}
}

export class Register {
  static readonly type = "[Auth] Register";

  constructor(public payload: BusinessPartner) {}
}

export class CheckifRegistered {
  static readonly type = "[Auth] CheckifRegistered";
  constructor(public payload: QueryAvailabilityModel) {}
}

export class Logout {
  static readonly type = "[Auth] Logout";
}

export class SyncAuth {
  static readonly type = "[Auth] SyncAuth";

  constructor(
    public basicCredentials: AuthBasicCredentials,
    public tokenCredentiasl: AuthTokenCredentials
  ) {}
}

export class CheckRefreshToken {
  static readonly type = "[Auth] CheckRefreshToken";
}

export class RefreshToken {
  static readonly type = "[Auth] RefreshToken";
}

export class LoginWithBasicAuth {
  static readonly type = "[Auth] LoginWithBasicAuth";
}

export class LoginBasicWithBasicAuth {
  static readonly type = "[Auth] LoginBasicWithBasicAuth";
}

export class AddBasicAuth {
  static readonly type = "[Auth] AddBasicAuth";

  constructor(
    public basicCredentials: { username: string; password: string }
  ) {}
}

export class UpdateAuthTokensCredentials {
  static readonly type = "[Auth] UpdateBasicAuthFromOtpTokens";

  constructor(public tokenCredentials: XAuthTokens) {}
}
